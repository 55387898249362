import { Component, OnInit, OnDestroy } from '@angular/core';
import { interval, Subscription } from 'rxjs';
import { TimesheetService } from '../../services/demands/timesheet.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DemandsService } from '../../services/demands/demands.service';

@Component({
  selector: 'app-timesheet',
  templateUrl: './timesheet.component.html',
  styleUrls: ['./timesheet.component.scss']
})
export class TimesheetComponent implements OnInit, OnDestroy {

  timesheetForm!: FormGroup;
  timesheet: any = null;
  start!: Date;
  timer: string | null = null;
  minutes: number = 0;

  loading = false;

  saved = false;
  show = false;
  stopped = false;

  private timerSubscription?: Subscription;

  constructor(
    private timesheetService: TimesheetService, 
    private demandService: DemandsService,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit(): void {
    this.initForm();
    this.loadActiveTimesheet();
    this.listenForStartTime();
  }

  ngOnDestroy(): void {
    this.timerSubscription?.unsubscribe();
  }

  private initForm(): void {
    this.timesheetForm = this.formBuilder.group({
      id: ['', Validators.required],
      title: ['', Validators.required],
    });
  }

  private loadActiveTimesheet(): void {
    this.timesheetService.getUserActiveTimesheet().subscribe({
      next: (result: any) => { 
        if (result) {
          console.log("Tem timesheet ativo", result);
          this.timesheet = result;
          this.startTimer(new Date(result.start.replace(' ', 'T')));
        } else {
          this.clearTimesheetStorage();
        }
      },
      error: console.error
    });
  }

  private listenForStartTime(): void {
    this.timesheetService.startTime$.subscribe((demandTaskId: number) => {
      if (demandTaskId && !this.timesheet) {
        this.startNewTimesheet(demandTaskId);
      }
    });
  }

  private startNewTimesheet(demandTaskId: number): void {
    this.loading = true;
    console.log("Iniciando timesheet...");
    this.timesheetService.start(demandTaskId).subscribe({
      next: (result: any) => {
        if (result) {
          this.timesheet = result;
          this.storeTimesheet(result);
          this.startTimer(new Date(result.start.replace(' ', 'T')));
          this.loading = false;
        }
      },
      error: console.error
    });
  }

  private startTimer(start: Date): void {
    this.start = start;
    this.timerSubscription = interval(1000).subscribe(() => {
      const elapsedTime = Math.floor((new Date().getTime() - this.start.getTime()) / 1000);
      this.timer = this.formatElapsedTime(elapsedTime);
      this.minutes = elapsedTime / 60;
    });
  }

  private formatElapsedTime(seconds: number): string {
    return new Date(seconds * 1000).toISOString().substr(11, 8);
  }

  stop(): void {
    this.timerSubscription?.unsubscribe();
    this.stopped = true;
    
    const roundedMinutes = Math.ceil(this.minutes);
    this.timer = this.formatElapsedTime(roundedMinutes * 60);

    this.timesheetService.stop({ duration: roundedMinutes }).subscribe({
      next: (result: any) => this.finalizeTimesheet(result),
      error: console.error
    });
  }

  save(): void {
    this.timesheetService.saveTitle(this.timesheetForm.value).subscribe({
      next: () => this.finalizeTimesheet(),
      error: console.error
    });
  }

  private finalizeTimesheet(result?: any): void {
    if (result) {
      this.timesheetForm.patchValue(result);
      this.timesheetService.setEndTime(result.demand_task_id);
    }
    
    this.clearTimesheetStorage();
    this.resetState();
  }

  private storeTimesheet(timesheet: any): void {
    localStorage.setItem('timesheet', JSON.stringify(timesheet));
  }

  private clearTimesheetStorage(): void {
    localStorage.removeItem('timesheet');
  }

  private resetState(): void {
    this.saved = true;
    this.timesheet = null;
    this.timesheetForm.reset();
    this.timer = null;
    this.show = false;
    this.stopped = false;
  }
}
