<div class="card z-index-2 h-100">
  <div class="card-header pb-2 pt-3 bg-transparent table-filter d-flex align-items-center">
      <h6 class="table-title mb-0">{{title}} ({{ projects?.total }})</h6>
      <!-- <button class="btn btn-primary ms-3 mb-0 border-radius-4 add-new" (click)="newProject()">+ Novo Projeto</button> -->

    <div class="view-toggle-buttons ms-auto me-2">
      <button type="button" class="btn-view-toggle  me-2 border-radius-8" (click)="view = 'list'" [class.btn-focus]="view === 'list'">
        <img src="assets/argon-dashboard-master/assets/img/claro/icons/list-view.svg" alt="">
      </button>
      <button type="button" class="btn-view-toggle border-radius-8" (click)="view = 'grid'" [class.btn-focus]="view === 'grid'">
        <img src="assets/argon-dashboard-master/assets/img/claro/icons/grid-view.svg" alt="">
      </button>
    </div>

      <div class="filters">
        <button class="btn-filters btn-filters-square me-2 border-radius-8" (click)="showFiltersBox = !showFiltersBox" [class.show]="showFiltersBox"><img src="assets/argon-dashboard-master/assets/img/claro/icons/filters.svg" alt=""></button>
        <div class="box-filters" *ngIf="showFiltersBox">
          <div class="form-group d-flex align-items-center">
            <label class="me-2 text-white text-end flex-grow-1 mb-0">Ordenar por</label>
            <select class="form-control w-60" [(ngModel)]="order">
              <option value="desc">Mais recentes</option>
              <option value="asc">Mais antigos</option>
            </select>
          </div>

          <!-- <div class="form-group d-flex align-items-center">
              <label class="me-2 text-white text-end flex-grow-1 mb-0">Área</label>
              <select name="" id="" class="form-control w-60" [(ngModel)]="area_id">
                <option value="">Selecione</option>
                <option [value]="area.id" *ngFor="let area of areas">{{ area.name }}</option>
              </select>
          </div> -->

          <!-- <div class="form-group d-flex align-items-center">
            <label class="me-2 text-white text-end flex-grow-1 mb-0">Data de crianção</label>
            <input type="date" class="form-control w-60" placeholder="Filtre por data" [(ngModel)]="created_at">
          </div> -->
          
          <button class="btn-action btn-filter btn-primary" (click)="getProjects()">Filtrar</button>
          <button class="text-white bg-transparent border-0 small" (click)="resetFilters()">Limpar</button>
        </div>
      </div>
      
      <div class="input-group search w-auto">
        <input type="text"  class="form-control" placeholder="Pesquisar..." [(ngModel)]="query" (input)="!query ? getProjects() : ''" (keyup.enter)="getProjects()" #search>
        <button class="btn btn-search p-2 m-0 shadow-none" type="button"><i class="fas fa-search fa-sm"></i></button>
      </div>
  </div>
  <div class="card-body p-3 pb-4">  

    <ngx-skeleton-loader 
      *ngIf="skeleton"
      count="5" 
      appearance="line" 
      [theme]="{height: '45px'}">
    </ngx-skeleton-loader>
    
    <div class="table-responsive p-0" *ngIf="!skeleton && view === 'list'">
        <table class="table align-items-center mb-0">
          <thead>
            <tr>
              <th class="text-center w-100"
              (click)="sortBy('name')"
              [class.asc]="sortColumn === 'name' && sortDirection === 'asc'" 
              [class.desc]="sortColumn === 'name' && sortDirection === 'desc'" 
              >Projeto</th>

              <th class="text-center"
              (click)="sortBy('demands_count')"
              [class.asc]="sortColumn === 'demands_count' && sortDirection === 'asc'" 
              [class.desc]="sortColumn === 'demands_count' && sortDirection === 'desc'" 
              >Demandas</th>

              <th class="text-center"
              (click)="sortBy('created_at')"
              [class.asc]="sortColumn === 'created_at' && sortDirection === 'asc'" 
              [class.desc]="sortColumn === 'created_at' && sortDirection === 'desc'" 
              >Data de criação</th>

              <th class="text-center"
              (click)="sortBy('user.name')"
              [class.asc]="sortColumn === 'user.name' && sortDirection === 'asc'" 
              [class.desc]="sortColumn === 'user.name' && sortDirection === 'desc'" 
              >Responsável</th>

              <th class="text-center"
              (click)="sortBy('progress')"
              [class.asc]="sortColumn === 'progress' && sortDirection === 'asc'" 
              [class.desc]="sortColumn === 'progress' && sortDirection === 'desc'" 
              >Progresso</th>
              <th class="text-center"></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let p of projects.data" class="pointer">
              <td class="text-center">
                  <div class="d-flex px-2 py-1">
                    <div class="d-flex flex-column justify-content-center">
                      <h6 class="mb-0text-uppercase">
                        <a [routerLink]="'/' + currentUser.group.name + '/projects/project/' + p.id">
                          <span class="project-code"></span>
                          <span class="project-name">{{p.name}}</span>
                        </a>
                      </h6>
                    </div>
                  </div>
              </td>
              <td class="text-center">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm"> 
                      <img src="assets/argon-dashboard-master/assets/img/claro/icons/demands_count.svg" class="me-2" alt="20">
                      <span>{{p.demands_count}}</span>
                    </h6>
                  </div>
              </td>
              <td class="text-center">
                {{p.created_at | date: 'dd/MM'}}
              </td>
              <td class="text-center">
                <div class="avatar-group ms-auto">
                  <div class="avatar avatar-lg avatar-sm rounded-circle">
                    <img [alt]="p.user.name" [title]="p.user.name" [ngbTooltip]="p.user.name" [src]="p.user.avatar ?? 'assets/argon-dashboard-master/assets/img/default-user.png'">
                  </div>
                </div>
              </td>
              <td class="text-center px-4">
                <div class="progress project-progress m-auto">
                  <div class="progress-bar bg-success" role="progressbar" [ngbTooltip]="p.progress + '/100'" [attr.aria-valuenow]="p.progress" [style.width]="p.progress + '%'"  aria-valuemin="0" aria-valuemax="100"></div>
                </div>
              </td>
              <td class="text-center">
                <button class="more-options">
                  <img src="assets/argon-dashboard-master/assets/img/claro/icons/options.svg" alt="">
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="text-center d-block py-5" *ngIf="projects?.total == 0">
          <small>Nenhum projeto encontrado!</small>
        </div>
    </div>

    <div class="container-fluid px-1" *ngIf="!skeleton && view === 'grid'">
      <div class="row">
        <div class="col-4" *ngFor="let p of projects.data">
          <div class="card item pointer project-card" [routerLink]="'/' + currentUser.group.name + '/projects/project/' + p.id">
            <div class="card-body p-0">
                <h6 class="mb-0 text-xs pointer">
                    <span class="project-code mb-1">{{ p.code }}</span>
                    <span class="project-name d-block">{{p?.name}}</span>
                </h6>
                <p class="text-xs mb-0 deadline mt-2">
                    <img src="assets/argon-dashboard-master/assets/img/claro/icons/calendar.svg" alt="" width="15">
                    {{ p.created_at | date : 'dd/MM/yyyy' }}
                </p>
                <div class="progress-info d-flex align-items-center justify-content-between mt-3">
                  <span>Progresso: {{p.progress}}%</span>
                  <span>
                    <svg class="me-2 demand-icon" width="18" height="19" viewBox="0 0 18 19" xmlns="http://www.w3.org/2000/svg">
                      <path d="M2.49731 5.34375C2.49731 3.27045 4.22938 1.58346 6.35809 1.58346H13.7817C13.5142 0.672422 12.6615 0 11.6416 0H2.29423C1.0609 0 0.0588379 0.975977 0.0588379 2.17721V14.4482C0.0588379 15.649 1.0609 16.6254 2.29423 16.6254H2.49731V5.34375Z"/>
                      <path d="M15.7058 3.16654H6.35847C5.12552 3.16654 4.12308 4.14252 4.12308 5.34375V16.8228C4.12308 18.0237 5.12514 19 6.35847 19H15.7058C16.9388 19 17.9412 18.024 17.9412 16.8228V5.34375C17.9412 4.14289 16.9391 3.16654 15.7058 3.16654ZM14.08 16.625H7.98386C7.64743 16.625 7.37424 16.3589 7.37424 16.0312C7.37424 15.7036 7.64743 15.4375 7.98386 15.4375H14.08C14.4165 15.4375 14.6896 15.7036 14.6896 16.0312C14.6896 16.3589 14.4165 16.625 14.08 16.625ZM14.08 13.4585H7.98386C7.64743 13.4585 7.37424 13.1924 7.37424 12.8647C7.37424 12.537 7.64743 12.271 7.98386 12.271H14.08C14.4165 12.271 14.6896 12.537 14.6896 12.8647C14.6896 13.1924 14.4165 13.4585 14.08 13.4585ZM14.08 10.6875H7.98386C7.64743 10.6875 7.37424 10.4214 7.37424 10.0938C7.37424 9.76607 7.64743 9.5 7.98386 9.5H14.08C14.4165 9.5 14.6896 9.76607 14.6896 10.0938C14.6896 10.4214 14.4165 10.6875 14.08 10.6875ZM14.08 7.52096H7.98386C7.64743 7.52096 7.37424 7.25488 7.37424 6.92721C7.37424 6.59953 7.64743 6.33346 7.98386 6.33346H14.08C14.4165 6.33346 14.6896 6.59953 14.6896 6.92721C14.6896 7.25488 14.4165 7.52096 14.08 7.52096Z"/>
                      </svg>
                      {{p.demands_count}} demandas
                  </span>
                </div>
                <div class="progress project-progress m-auto mt-2" [ngbTooltip]="p.progress + '/100'">
                  <div class="progress-bar bg-success" role="progressbar" [attr.aria-valuenow]="p.progress" [style.width]="p.progress + '%'"  aria-valuemin="0" aria-valuemax="100"></div>
                </div>
                <div class="d-flex align-items-center justify-content-between mt-4" style="line-height: normal">
                    <div class="avatar-group">
                        <div class="avatar avatar-lg avatar-sm rounded-circle m-0">
                            <img alt="Image placeholder" [title]="p.user.name" [ngbTooltip]="p.user.name" [src]="p.user?.avatar || 'assets/argon-dashboard-master/assets/img/default-user.png'">
                        </div>
                    </div>
                    <!-- <div class="text-end d-flex align-items-center comments">
                        <i class="fa fa-comments" aria-hidden="true"></i> <span class="text-xs ms-1">{{ p?.chats_count }}</span>
                    </div> -->
                </div>
            </div>
        </div>
        </div>
      </div>
    </div>

    <nav aria-label="Page navigation example" *ngIf="projects.last_page > 1 && !limit">
        <ul class="pagination justify-content-end mt-4">
            <li class="page-item active" *ngFor="let p of pagination"
                [class.active]="projects.current_page == p">
                <a class="page-link" href="javascript:;">{{ p }}</a>
            </li>
        </ul>
    </nav>
  </div>
</div>