import { Component, OnInit } from '@angular/core';
import { ClientsService } from './shared/services/user-management/clients.service';
import { UserService } from './shared/services/user-management/user.service';
import { ThemeService } from './shared/services/system/theme.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  title = 'gestor-carrefour';
  currentUser:any;
  client:any;
  loading = true;

  constructor(
    private userService: UserService,
    private clientService: ClientsService,
    private themeService: ThemeService
  ) {
    console.log(this.loading);
    this.initApp();
  }

  ngOnInit(): void {
    
  }

  private async initApp() {
    try {
      await this.themeService.loadTheme(); // Aguarda o carregamento do tema
    } catch (error) {
      console.error("Erro ao carregar tema:", error);
    } finally {
      this.loading = false; // Somente agora marca como carregado
      console.log(this.loading)
    }
  }

  isUserLoggedIn(): boolean {
    return !!localStorage.getItem('user'); 
  }
}
