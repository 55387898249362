<div class="container-fluid">
  <div class="row">
    <div class="col-12">
      <ngx-skeleton-loader *ngIf="skeleton" count="5" appearance="line"
        [theme]="{height: '40px', marginBottom: '10px', borderRadius: '8px'}">
      </ngx-skeleton-loader>
    </div>
  </div>
</div>
<div class="requester-demand-form" *ngIf="!skeleton">
  <div class="card-header p-4">
      <span class="text-uppercase d-block">Solicitações de</span>
      <span class="text-uppercase d-block">Demandas</span>
  </div>
  <div class="container-fluid py-4 px-5" id="requester-info" *ngIf="!demand_id">
    <div class="row">
      <div class="col-12">
        <p class="box-title text-uppercase">Informações demandante</p>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="form-group mb-0">
          <label><strong>Nome do demandante</strong></label>
          <input type="text" class="form-control w-100" id="user_name" [value]="user.name" disabled>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <div class="form-group mt-4 mb-0">
          <label><strong>E-mail do demandante</strong></label>
          <input type="text" class="form-control w-100" id="user_name" [value]="user.email" disabled>
        </div>
      </div>
      <div class="col-6">
        <div class="form-group mt-4 mb-0">
          <label><strong>Telefone do demandante</strong></label>
          <input type="text" 
            class="form-control w-100" 
            id="user_name" 
            [value]="user.phone"
            disabled>
        </div>
      </div>
    </div>
    <div class="row">
      <ng-container *ngFor="let userField of userFields">
        <ng-container *ngIf="hasValue(userField)">
          <ng-container *ngTemplateOutlet="renderField; context: { $implicit: userField }"></ng-container>
        </ng-container>
      </ng-container>
      <ng-template #renderField let-field>
        <div *ngIf="hasValue(field)" class="col-6">
          <div class="form-group mt-4 mb-0">
            <label><strong>{{ field.name }}</strong></label>
            <input
              type="text" [disabled]="demand_id != null"
              class="form-control w-100"
              [value]="field?.selecteds[0]?.value"
              disabled
            />
          </div>
        </div>
        <ng-container *ngFor="let child of field.children">
          <ng-container *ngIf="hasValue(child)">
            <ng-container *ngTemplateOutlet="renderField; context: { $implicit: child }"></ng-container>
          </ng-container>
        </ng-container>
      </ng-template>
    </div>    
  </div>
  </div>
  <form [formGroup]="demandForm"  *ngIf="!skeleton">
    <div class="container-fluid p-5">
      <div class="row">
          <div class="col-12">
            <p class="box-title text-uppercase">Detalhes sobre a demanda</p>
        </div>
      </div>
      <!-- <div class="row px-3" style="background: #FAF4F4">
        <div class="col-12">
          <div class="form-group mt-4 mb-0">
            <label>
              <strong>Nome do projeto</strong> <span class="text-danger"> *</span>
            </label>
            <ng-select
              [items]="projects"
              bindLabel="name"
              [addTag]="true"
              [addTagText]="'Adicionar novo projeto'"
              placeholder="Selecione ou digite para adicionar..."
              formControlName="project"
              (change)="setProject($event)"
              >
            </ng-select>
          </div>
        </div>
      </div> -->
      <div class="row px-3" style="background: #FAF4F4">
        <div class="col-12">
          <div class="form-group mt-4 mb-0">
            <label>
              <strong>Nome da demanda</strong> <span class="text-danger"> *</span>
            </label>
            <input type="text" [disabled]="demand_id != null" class="form-control" id="name" formControlName="name" placeholder="Digite aqui...">
          </div>
        </div>
      </div>
      <div class="row px-3" style="background: #FAF4F4">
        <div class="col-6">
          <div class="form-group mt-4 mb-0">
            <label>
              <strong>Data desejada da entrega</strong> <span class="text-danger"> *</span>
            </label>
            <input type="date" [disabled]="demand_id != null" class="form-control" id="date" formControlName="deadline">
          </div>
        </div>
      </div>

      <div class="row px-3" style="background-color: #FAF4F4">
        <div class="col-12">
          <app-fields
          [fields]="fields"
          context="demand_view"
          (changeArea)="setArea($event)"
          (allFieldsFilled)="isFieldsFilled($event)"
          [clickSubmit]="clickSubmit"
          ></app-fields>
        </div>
      </div>
      
      <div class="row px-3" style="background: #FAF4F4">
        <div class="col-12">
          <div class="form-group mt-4 mb-0">
            <label>
              <strong>Descreva a demanda</strong> <span class="text-danger"> *</span>
            </label>
            <textarea class="form-control" name="" id="requester-description" formControlName="requester_description" rows="7"></textarea>
          </div>
        </div>
      </div>
      <div class="row px-3" style="background: #FAF4F4">
        <div class="col-lg-12">
          <div class="form-group mt-4 mb-0">
            <label class="form-label mb-3">Tem alguma apresentação ou documento deste tema?</label>
            <div class="center">
              <app-file-upload [formData]="formData"></app-file-upload>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-12">
          <button class="btn btn-sx btn-primary mb-0 text-uppercase py-2 px-4 ms-auto w-100 mt-2"
            (click)="!demand_id ? save(demandForm) : save(demandForm, true)" [disabled]="demandForm.invalid || loading">{{loading ? "Enviando" :
            "Enviar"}}</button>
        </div>
      </div>
        <!-- <div class="col-12">
          <div class="box-alert p-4 mt-6">
            <img src="../../../../assets/argon-dashboard-master/assets/img/claro/icon-alert.svg" alt=""
              class="icon-alert">
            <p class="message pe-3">Nunca envie senhas ou dados confidenciais por meio de formulários desconhecidos.
              Certifique-se de que este formulário foi gerado por sua empresa ou por uma empresa confiável.</p>
            <a href="#" class="me-5">Denunciar abuso</a>
            <a href="#">Termos e condições</a>
          </div>
        </div> -->
    </div>
  </form>
</div>