<div class="container-fluid px-0">
    <div class="row">
        <div [ngClass]="getColumnClass(columnsPerLine)" *ngIf="areas && areas != null && showAreaSelector && !model_id">
            <div class="form-group mb-0 mt-4">
                <label [ngStyle]="{'color': styles.labelColor}">Selecione {{context === 'demand_view' ? 'o tipo da demanda' : 'a área do usuário'}}<span class="text-danger">*</span></label>
                <ng-select 
                [items]="areas" 
                bindLabel="name" 
                bindValue="id"
                [searchable]="false"
                [clearable]="false"
                [(ngModel)]="area_id"
                placeholder="Selecione {{context === 'demand_view' ? 'o tipo da demanda' : 'a área do usuário'}}"
                (change)="handleArea($event)">
                </ng-select>
            </div>
        </div>
        <ng-container *ngIf="fields?.length > 0">
            <ng-container *ngFor="let field of fields; let parentIndex = index">
                <div [ngClass]="getColumnClass(columnsPerLine)" *ngIf="(!field.area_id || field.area_id === area_id) && hasPermission(field)">
                    <div class="form-group mb-0" [ngClass]="context === 'demand_view' ? 'mt-4' : 'mt-2'">
                        <label [ngStyle]="{'color': styles.labelColor}">{{ field?.main_label }} <span class="text-danger" *ngIf="field.required">*</span></label>
                        <label class="fw-normal d-block" *ngIf="field.sub_label">{{ field?.sub_label }}</label>

                        <ng-container [ngSwitch]="field.input">
                            <!-- checkbox/radio -->
                            <div *ngSwitchCase="'checkbox'">
                            
                            </div>
                            <!-- select -->
                            <ng-container *ngSwitchCase="'select'">
                                <ng-select 
                                [items]="field.values" 
                                [multiple]="field.multiple" 
                                bindValue="id"
                                bindLabel="value"
                                [(ngModel)]="field.selecteds" 
                                [ngModelOptions]="{standalone:true}"
                                [closeOnSelect]="!field.multiple" 
                                [placeholder]="field.main_label"
                                (change)="onSelectFieldValueChange(field, $event)"
                                [disabled]="model_id && ['requester'].includes(currentUser.group.name)">
                            </ng-select>
                            <!-- <p>{{ field.selecteds | json}}</p> -->
                            <small class="mt-2 field-filled-error" *ngIf="field?.filled === false && clickSubmit">Este campo é obrigatório.</small>
                            </ng-container>
                        </ng-container>
                    </div>
                </div>
                
                <!-- Render recursive children -->
                <ng-container  *ngIf="field.selecteds && field.children">
                    <ng-container
                        *ngTemplateOutlet="renderRecursive; context: { $implicit: field.children, parent: field }">
                    </ng-container>
                </ng-container>
            </ng-container>
        </ng-container>
    </div>
</div>

<ng-template #renderRecursive let-children let-parent="parent">
    <ng-container *ngFor="let child of children" >
    <div [ngClass]="getColumnClass(columnsPerLine)" *ngIf="(!child.area_id || child.area_id === area_id) && shouldDisplayChild(child, parent)">
        <div class="form-group mb-0" [ngClass]="context === 'demand_view' ? 'mt-4' : 'mt-2'">
            <ng-container>
                <label [ngStyle]="{'color': styles.labelColor}">{{ child?.main_label }} <span class="text-danger" *ngIf="child.required">*</span></label>
                <ng-select 
                [items]="child.filteredValues"
                bindValue="id"
                bindLabel="value" 
                [(ngModel)]="child.selecteds"
                [ngModelOptions]="{standalone:true}"
                [multiple]="child.multiple" 
                [closeOnSelect]="!parent.multiple"
                (change)="onSelectFieldValueChange(child, $event)"
                [placeholder]="child.main_label"
                [disabled]="model_id && ['requester'].includes(currentUser.group.name)">
                </ng-select>
                <!-- <p>{{ child.selecteds | json}}</p> -->
                <small class="mt-2 field-filled-error" *ngIf="!child?.filled && clickSubmit">Este campo é obrigatório.</small>

                <!-- Recursive call -->
                <ng-container *ngIf="child.selecteds && child.children">
                    <ng-container
                        *ngTemplateOutlet="renderRecursive; context: { $implicit: child.children, parent: child }"></ng-container>
                </ng-container>
            </ng-container>
        </div>
    </div>
</ng-container>
</ng-template>