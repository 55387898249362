<div class="w-100 d-flex align-items-center">
    <div class="box-users">
        <button class="user-info-btn me-1 p-0" (click)="toggleUserBox()" #userListBtnEl>
        <i class="fa-solid fa-circle-user text-secondary user-btn" ngbTooltip="Selecionar usuários"></i>
        </button>
        <div class="list shadow" *ngIf="showUserBox" #userListEl>
        <div class="input-group">
            <input type="text" class="form-control bg-white input-search-users" placeholder="Pesquisar..." [(ngModel)]="searchQuery" aria-label="Pesquisar usuários">
        </div>
        <h5 class="p-2 mb-0">{{ label }}</h5>
        <ul class="list-unstyled mb-0">
            <ng-container *ngFor="let user of users">
            <li class="item d-flex p-2 align-items-center pointer" 
                (click)="toggleUser(user, $event)" 
                *ngIf="filterUsers(user) && !isUserSelected(user)">
                <img [src]="user.avatar || 'assets/argon-dashboard-master/assets/img/default-user.png'" class="avatar avatar-sm" [alt]="'Avatar de ' + user.name">
                <span class="ms-2 text-xs user-name">{{ user.name }}</span>
            </li>
            </ng-container>
        </ul>
        </div>
    </div>
    <img 
    [src]="user.avatar || 'assets/argon-dashboard-master/assets/img/default-user.png'"
    [ngbTooltip]="user.name"
    *ngFor="let user of selectedUsers"
    class="avatar me-1"
    (click)="removeUser(user)">
</div>