import { Component, Input, OnInit } from '@angular/core';
import { ProjectsService } from 'src/app/shared/services/demands/projects.service';
import { AreaService } from 'src/app/shared/services/user-management/area.service';
import { UserService } from 'src/app/shared/services/user-management/user.service';
@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss'],
})
export class ProjectsComponent implements OnInit {

  //data
  currentUser: any = null;
  areas: any = null
  projects:any = {data:[],last_page:0,current_page:0};
  
  //config
  @Input() title = 'Lista de Projetos';
  pagination = [];
  skeleton = true;
  showFiltersBox = false;
  
  //filters
  order = 'desc'
  limit:any = null;
  area_id: any = '';
  query:any = '';
  created_at:any = '';
  view:any = 'list'

  //order  
  sortColumn = '';
  sortDirection: 'asc' | 'desc' = 'asc';

  constructor(
    private userService: UserService,
    private areaService: AreaService,
    private projectsService: ProjectsService
  ) { }

  ngOnInit(): void {
    this.currentUser = this.userService.getLocalUser();
    this.area_id = this.currentUser.area_id;
    console.log(this.area_id);
    this.getProjects();
    this.getAreas();
  }


  sortBy(column: string) {
    this.sortDirection = this.sortColumn === column && this.sortDirection === 'asc' ? 'desc' : 'asc';
    this.sortColumn = column;
  
    this.projects.data.sort((a, b) => {
      const valueA = this.getValue(a, column);
      const valueB = this.getValue(b, column);
  
      return valueA > valueB ? (this.sortDirection === 'asc' ? 1 : -1) :
             valueA < valueB ? (this.sortDirection === 'asc' ? -1 : 1) : 0;
    });
  }

   // Para acessar propriedades aninhadas (ex: "status.name", "user.email")
   getValue(obj: any, column: string) {
    return column.split('.').reduce((o, key) => o?.[key], obj) || '';
  }



  getProjects() { 
    this.skeleton = true;
    this.projectsService.getAll({
      order: this.order,
      limit: this.limit,
      paginate: 20,
      created_at: this.created_at,
      query: this.query
    }).subscribe({
      next: (result: any) => {
        this.projects = result;
        this.skeleton = false;
      },
      error: err => console.log(err)
    })
  }

  resetFilters() {
    this.limit = '', 
    this.query = '',
    this.order = '',
    this.created_at = '',
    this.area_id = '',
    this.view = 'list';
  }

  newProject() { 

  }

  getAreas() { 
    this.areaService.getAll().subscribe({
      next: (result) => this.areas = result,
      error: (err) => console.log(err)
    })
  }

}
