<ngx-skeleton-loader 
*ngIf="skeleton" 
count="4" 
appearance="line"
[theme]="{height: '50px', marginBottom: '0', borderRadius: '0'}">
</ngx-skeleton-loader>

<div *ngIf="activities && activities.length" class="history">
    <ul class="p-0">
      <li *ngFor="let activity of activities" class="d-flex align-items-center p-2">
        <small class="date me-3">
            <strong>{{ activity.created_at | date: 'dd/MM'}}</strong>
            <span>{{ activity.created_at | date: 'HH:mm' }}</span>
        </small>
        <img [src]="activity.causer.avatar ?? 'assets/argon-dashboard-master/assets/img/default-user.png'" class="avatar me-2" [ngbTooltip]="activity.causer.name" [alt]="activity.causer.name">
         <small>{{ activity.description }}</small>
      </li>
    </ul>
  </div>
<div *ngIf="activities && !activities.length">
    <p>Nenhuma atividade registrada.</p>
</div>