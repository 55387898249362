import { Injectable } from '@angular/core';
import { BaseService } from 'src/app/core/shared/services/base.service';

@Injectable({
  providedIn: 'root'
})
export class DemandsService extends BaseService {

  getAll(filter: any = {}) {
    return this.post(`demands/filter`, filter)
  }

  getById(id: number) {
    return this.get(`demands/${id}`)
  }

  getText(id: number) {
    return this.get(`demands/text/${id}`)
  }

  updateStatus(data: any) {
    return this.post(`demands/status`, data)
  }

  save(data: FormData) {
    return this.post(`demands`, data)
  }

  duplicate(demand_id:number, data: FormData) {
    return this.post(`demands/${demand_id}/duplicate`, data)
  }

  reject(id: number) {
    return this.post(`demands/reject/${id}`, {})
  }

  approve(id: number, data:any) {
    return this.post(`demands/approve/${id}`, data)
  }

  saveSolicitation(data: FormData) {
    return this.post(`demands/solicitations`, data)
  }

  update(data: FormData, id: number) {
    return this.post(`demands/${id}`, data)
  }

  updateBriefing(id: number, data: any) {
    return this.post(`demands/briefing/${id}`, data)
  }

  setEmoji(demand_id:number, data:any) {
    return this.post(`demands/${demand_id}/emoji`, data)
  }

  setProject(demand_id:number, data:any){
    return this.post(`demands/${demand_id}/project`, data)
  }

  setManager(demand_id:number, manager_id:any){
    return this.post(`demands/${demand_id}/manager`, {manager_id: manager_id})
  }

  deleteManager(demand_id:number){
    return this.delete(`demands/${demand_id}/manager`)
  }

  deleteById(id: number) {
    return this.delete(`demands/${id}`)
  }

  test(id: number) {
    return this.post(`test-update-demand/${id}`, {})
  }

  getDemandsStatusCount() { 
    return this.get(`status/count/demands`);
  }

  saveModelFieldValue(model_id:number, model_type:string, data:any) { 
    return this.post(`fields/values/model/${model_type}/${model_id}`, data)
  }

  syncModelFieldValues(model_id:number, model_type:string, data:any) { 
    return this.post(`fields/values/model/${model_type}/${model_id}/sync`, data)
  }

  deleteModelFieldValue(model_id:number, model_type:string, data:any) { 
    return this.post(`fields/values/model/${model_type}/${model_id}/delete`, data)
  }

  getFields(model_id:number, model_type:string, filter:any) { 
    return this.post(`fields/values/model/${model_type}/${model_id}/filter`, filter)
  }

}
