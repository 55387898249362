// app.module.ts

import { NgModule, LOCALE_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { InterceptorModule } from './interceptors/interceptor.module';
import { SharedModule } from './shared/shared.module'; // Importando o SharedModule

import { AppComponent } from './app.component';
import { LoginComponent } from './pages/login/login.component';
import { ErrorComponent } from './pages/error/error.component';
import { ProjectsComponent } from './pages/projects/projects.component';
import { AnalyticsComponent } from './pages/analytics/analytics.component';

import localept from '@angular/common/locales/pt';
import { registerLocaleData } from '@angular/common';
import { JwtModule } from '@auth0/angular-jwt';
import { RouterModule, TitleStrategy } from '@angular/router';
import { RegisterComponent } from './pages/register/register.component';
import { OtpComponent } from './pages/otp/otp.component';
import { ProjectDetailComponent } from './pages/projects/pages/project-detail/project-detail.component';
import { UserProfileComponent } from './pages/user-profile/user-profile.component';
import { CustomTitleStrategy } from './shared/services/strategies/custom-title-strategy';
import { TasksManagementComponent } from './pages/management/tasks-management/tasks-management.component';
import { TasksManagementFormComponent } from './pages/management/tasks-management/tasks-management-form/tasks-management-form.component';
import { AreasManagementComponent } from './pages/management/areas-management/areas-management.component';
import { AreasManagementFormComponent } from './pages/management/areas-management/areas-management-form/areas-management-form.component';


registerLocaleData(localept, 'pt');

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ErrorComponent,
    RegisterComponent,
    ProjectsComponent,
    ProjectDetailComponent,
    AnalyticsComponent,
    OtpComponent,
    UserProfileComponent,
    TasksManagementComponent,
    TasksManagementFormComponent,
    AreasManagementComponent,
    AreasManagementFormComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    RouterModule,
    InterceptorModule,
    SharedModule, // módulos compartilhados
    JwtModule.forRoot({
      config: {
        tokenGetter: jwtTokenGetter,
        allowedDomains: ["localhost:4200", "smarthub.alamo.com.vc", "127.0.0.1:8000", 'smarthubnovo.alamo.com.vc', 'aurora.fiqueligadonews.com.br', 'aurora.alamo.com.vc'],
        authScheme: "Bearer"
      }
    })
  ],
  providers: [{ 
    provide: LOCALE_ID, useValue: 'pt' ,
  },
  { provide: TitleStrategy, useClass: CustomTitleStrategy }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

export function jwtTokenGetter() {
  return localStorage.getItem('access_token');
}
