import { Component, OnInit, Input, ViewChild, HostListener } from '@angular/core';
import { DemandsService } from 'src/app/shared/services/demands/demands.service';
import { DemandTasksService } from '../../services/demands/demand-tasks.service';
import { AreaService } from '../../services/user-management/area.service';
import { UserService } from '../../services/user-management/user.service';
import { StatusService } from '../../services/demands/status.service';
import { Clipboard } from '@angular/cdk/clipboard';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { AnimationItem } from 'lottie-web';
import { AnimationOptions } from 'ngx-lottie';
import { SidebarFilterComponent } from '../sidebar-filter/sidebar-filter.component';
import emojis from '../../../../assets/argon-dashboard-master/assets/img/claro/icons/emojis/emojis.json';

interface TableColumn {
  field: string;
  label: string;
  class?: string;
  visible?: boolean;
}

@Component({
  selector: 'app-demand-table',
  templateUrl: './demand-table.component.html',
  styleUrls: ['./demand-table.component.scss']
})
export class DemandTableComponent implements OnInit {
  options: AnimationOptions = {
    path: '/assets/argon-dashboard-master/assets/animation/animation_ll6pcbjg.json'
  };

  itemsPerPageOptions: number[] = [10, 25, 50, 100, 200];
  pagination: number[] = [];

  // Inputs
  @Input() project_id;
  @Input() area_id;
  @Input() limit;
  @Input() paginate: number = 10;
  @Input() context: 'demand' | 'task' = 'demand';
  @Input() solicitations: boolean = false;
  @Input() title: string = 'DEMANDAS';
  @Input() sharedUrl: string | null = null;
  @Input() emptyTableText: string = 'Nenhuma demanda no momento';
  @Input() addNew: boolean = true;
  @Input() activeTab: 'my-records' | 'all-records' = 'my-records';
  
  @Input() saveOptionsOnFilter:boolean = true;

  @ViewChild('sideBarFilter') private sideBarFilter!: SidebarFilterComponent;

  // Estado
  skeleton: boolean = true;
  demands: any = { data: [] };
  demandsTasks: any = { data: [] };
  demand_id: number = 0;
  @Input() filters: any = {}; // Filtros ajustados para envio ao backend
  originalFilters: any = {}; // Filtros originais do sidebar
  status: any[] = [];
  header: any = null;
  areas: string = '';
  sortColumn: string = '';
  sortDirection: 'asc' | 'desc' = 'asc';
  showFiltersBox: boolean = false;
  demandsCount: number = 0;
  currentUser: any = null;
  selectedRowIndex: number | null = null;

  demandsTableColumns: TableColumn[] = [];
  demandTasksTableColumns: TableColumn[] = [];

  constructor(
    private demandsService: DemandsService,
    private demandTaskService: DemandTasksService,
    private areaService: AreaService,
    private userService: UserService,
    private statusService: StatusService,
    private clipboard: Clipboard,
    private modalService: NgbModal
  ) {}

  ngOnInit(): void {
    this.currentUser = this.userService.getLocalUser();
    this.initializeTableColumns();
    console.log(this.project_id);
  }

  ngAfterViewInit(): void {
    this.sideBarFilter.filtersLoaded.subscribe((filters: any) => {
      console.log(filters);
      this.originalFilters = { ...filters }; // Armazenar filtros originais
      this.applyTabRules(); // Aplicar regras das abas
      this.getItems();
    });
  }

  private initializeTableColumns(): void {
    const isRequester = ['requester'].includes(this.currentUser.group.name);
    this.demandsTableColumns = [
      { field: 'name', label: 'Demanda', class: 'w-60' },
      { field: 'deadline', label: 'Prazo' },
      { field: 'tasks_count', label: 'Tarefas', visible: !isRequester },
      { field: 'involved_users', label: 'Envolvidos', visible: !isRequester },
      { field: 'status.name', label: 'Status da demanda' },
      { field: '', label: '' }
    ];
    this.demandTasksTableColumns = [
      { field: 'demand.name', label: 'Demanda', class: 'w-60' },
      { field: 'task.name', label: 'Tarefa' },
      { field: 'due_date', label: 'Prazo' },
      { field: 'status.name', label: 'Status' },
      { field: '', label: '' }
    ];
  }

  animationCreated(animationItem: AnimationItem): void {
    console.log(animationItem);
  }

  getStatus(): void {
    this.statusService.getAll({ context: this.context }).subscribe({
      next: (result: any[]) => (this.status = result),
      error: (err) => console.error('Erro ao carregar status:', err)
    });
  }

  sortBy(column: string): void {
    this.sortDirection = this.sortColumn === column && this.sortDirection === 'asc' ? 'desc' : 'asc';
    this.sortColumn = column;
    const data = this.context === 'demand' ? this.demands.data : this.demandsTasks.data;
    data.sort((a: any, b: any) => {
      const valueA = this.getValue(a, column);
      const valueB = this.getValue(b, column);
      return valueA > valueB ? (this.sortDirection === 'asc' ? 1 : -1) :
             valueA < valueB ? (this.sortDirection === 'asc' ? -1 : 1) : 0;
    });
  }

  private getValue(obj: any, column: string): any {
    return column.split('.').reduce((o, key) => o?.[key], obj) ?? '';
  }

  getRecords(context: 'demand' | 'task' = this.context, activeTab: 'my-records' | 'all-records' = this.activeTab): void {
    this.context = context;
    this.activeTab = activeTab;
    this.demands = { data: [] };
    this.demandsTasks = { data: [] };
    this.pagination = []; // Resetar paginação
    this.applyTabRules();
    this.getItems();
  }

  private applyTabRules(): void {
    // Começar com os filtros originais do sidebar
    this.filters = { ...this.originalFilters };

    if (this.activeTab === 'my-records') {
      if (this.context === 'demand') {
        // "Minhas Demandas": Mostrar apenas demandas do usuário logado
        // if(this.currentUser.group.name === 'requester') this.filters.requester_id = [this.currentUser.id];
        this.filters.involved_user_id = [this.currentUser.id];
      } else if (this.context === 'task') {
        // "Minhas Tarefas": Mostrar apenas tarefas atribuídas ao usuário logado
        this.filters.involved_user_id = [this.currentUser.id];
      }
    } else if (this.activeTab === 'all-records' && this.context === 'demand') {
      // "Todas as Demandas": Usar os filtros originais do sidebar, incluindo requester_id selecionado
      // Não sobrescrever requester_id, apenas usar o que veio do originalFilters
    }

    console.log(this.filters);
  }

  private getItems(): void {
    this.filters.paginate = this.paginate;
    this.skeleton = true;
    this.context === 'demand' ? this.getDemands() : this.getDemandsTasks();
  }

  getDemands(): void {
    console.log(this.filters);
    this.demandsService.getAll(this.filters).subscribe({
      next: (result: any) => {
        this.demands = result;
        if (this.limit) this.demands.data = this.demands.data.slice(0, this.limit);
        this.updatePagination(result.last_page);
        this.skeleton = false;
      },
      error: (err) => {
        console.error('Erro ao carregar demandas:', err);
        this.skeleton = false;
      }
    });
  }

  private getDemandsTasks(): void {
    this.demandTaskService.getAll(this.filters).subscribe({
      next: (result: any) => {
        this.demandsTasks = result;
        if (this.limit) this.demandsTasks.data = this.demandsTasks.data.slice(0, this.limit);
        this.updatePagination(result.last_page);
        this.skeleton = false;
      },
      error: (err) => {
        console.error('Erro ao carregar tarefas:', err);
        this.skeleton = false;
      }
    });
  }

  private updatePagination(lastPage: number): void {
    if (this.pagination.length === 0) {
      this.pagination = Array.from({ length: lastPage }, (_, i) => i + 1);
    }
  }

  toggleOptions(index: number): void {
    this.selectedRowIndex = this.selectedRowIndex === index ? null : index;
  }

  deleteDemand(demand_id: number): void {
    this.demandsService.deleteById(demand_id).subscribe({
      next: () => this.getItems(),
      error: (err) => console.error('Erro ao excluir demanda:', err)
    });
  }

  @HostListener('document:click', ['$event'])
  handleClickOutside(event: Event): void {
    const target = event.target as HTMLElement;
    if (!target.closest('.btn-options') && !target.closest('.options-box')) {
      this.selectedRowIndex = null;
    }
  }

  goPage(page: number): void {
    this.filters.page = page;
    this.getItems();
  }

  copyToClipboard(): void {
    if (this.sharedUrl) {
      this.clipboard.copy(this.sharedUrl);
      Swal.fire({
        position: 'top-end',
        icon: 'success',
        text: 'Copiado para a área de transferência',
        showConfirmButton: false,
        timer: 1500,
      });
    }
  }

  showDemand(demand_id: number, content: any): void {
    this.demand_id = demand_id;
    this.modalService.open(content, { windowClass: 'custom-modal', size: 'xl' });
  }

  newDemand(content: any): void {
    this.modalService.open(content, { size: 'lg' });
  }

  demandHeader(data: any): void {
    this.header = data;
  }

  getEmoji(emoji_id: number): string | null {
    if (!emoji_id) return null;
    const emoji = emojis.find((e: any) => e.id === emoji_id);
    return emoji?.emoji ?? null;
  }

  addOpacity(color: string, opacity: number): string {
    const hex = color.replace('#', '');
    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);
    return `rgba(${r}, ${g}, ${b}, ${opacity})`;
  }


  getFixedFilters() { 
    let filters:any = {};

    if(this.context === 'demand') filters.demand_task_status_id = []
    if(this.context === 'task')   filters.demand_status_id = []

    if(this.project_id) filters.project_id = this.project_id;
    // if(this.activeTab === 'my-records') filters.area_id = [];
    
    //usar permissões futuramente
    if(this.currentUser.group.name === 'requester' || this.activeTab === 'my-records') {
        filters.involved_user_id = [this.currentUser.id];
    } 

    return filters;
  }
}