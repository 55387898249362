import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { StatusService } from '../../services/demands/status.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-box-status-overview',
  templateUrl: './box-status-overview.component.html',
  styleUrls: ['./box-status-overview.component.scss']
})
export class BoxStatusOverviewComponent implements OnInit {

  skeleton = true;
  status:any
  @Input() context:any = 'demand';
  @Input() columns:number = 12;
  @Input() identifiers:any
  @Input() area_id:any = null;
  @Input() requester_id:any = null;
  @Input() start:any = ''
  @Input() deadline:any = ''
  @Input() showNewDemandButton = false;

  constructor(
    private statusService: StatusService,
    private modalService: NgbModal
  ) { }

  ngOnInit(): void {
    this.getStatusOverview();
  }

  ngOnChanges(changes: SimpleChanges): void {
    // Verifica se qualquer @Input foi alterado e chama getStatusOverview
    const hasChanged = Object.keys(changes).some(change => !changes[change].firstChange);
    if (hasChanged) {
      this.getStatusOverview();
    }
  }

  floor(num: number): number {
    return Math.floor(num);
  }

  getStatusOverview() {
    this.skeleton = true;
    this.status = [];
    let filter:any = { 
      context: this.context,
      limit: 1000,
      area_id: this.area_id,
      requester_id: this.requester_id,
      start: this.start,
      deadline: this.deadline
    }

    if (this.identifiers) filter.identifiers = this.identifiers;
    
    console.log(filter)

    this.statusService.getAll(filter).subscribe({
      next: (result: any) => {
        console.log(result);
        this.status = result
        this.skeleton = false;
      },
      error: (err) => console.log(err)
    })
  }

  newDemand(content: any) {
    this.modalService.open(content, { size: 'lg' });
  }

  addOpacity(color: string, opacity: number): string {

    const hex = color.replace('#', '');
    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);
  
    return `rgba(${r}, ${g}, ${b}, ${opacity})`;
  }
}
