import { Component, ElementRef, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Clipboard } from '@angular/cdk/clipboard';
import { NgxFileDropEntry } from 'ngx-file-drop';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Swal from 'sweetalert2';
import { URL_API } from 'src/app/core/shared/services/api';
import { ClientsService } from 'src/app/shared/services/user-management/clients.service';
import { DemandsService } from 'src/app/shared/services/demands/demands.service';
import { PartsTypesService } from 'src/app/shared/services/demands/parts-types.service';
import { PartsService } from 'src/app/shared/services/demands/parts.service';
import { StatusService } from 'src/app/shared/services/demands/status.service';
import { UserService } from 'src/app/shared/services/user-management/user.service';
import { ManagersService } from 'src/app/shared/services/user-management/managers.service';
import { map, Observable, startWith } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
// import { TimesheetService } from '../../services/demands/timesheet.service';

import emojis from '../../../../assets/argon-dashboard-master/assets/img/claro/icons/emojis/emojis.json';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DemandEventsService } from '../../services/demands/demand-events.service';
import { HistoryComponent } from '../history/history.component';
import { FieldService } from '../../services/data/field.service';

@Component({
  selector: 'app-demands-form',
  templateUrl: './demands-form.component.html',
  styleUrls: ['./demands-form.component.scss']
})
export class DemandsFormComponent implements OnInit {

  @ViewChild('content') private requesterFormModal!: ElementRef;
  @Input() modal = false
  // showTimesheet = false;
  @Input() showCloseButton = true
  @Input() showRequester = true;
  @Input() showActionsButtons = true;
  @Input() context = 'demand_view';
  @Input() showAreaSelector = true;
  @Input() triggerArea:any = {
    show: true,
    disabled: true,
  };
  @Input() emoji_id:any = null
  @Output() statusDemand   = new EventEmitter();
  @Output() updateCalendar = new EventEmitter();
  @Output() cancelModal    = new EventEmitter();
  @Output() demandHeader   = new EventEmitter();
  @Output() emoji          = new EventEmitter();
  
  @ViewChild('fileDate', { static: false }) fileDate!: ElementRef;

  public Editor = ClassicEditor;
  contentControl = new FormControl('');
  loading = false

  @Input() chats = true;
  @Input() fieldSize:string = 'col-4';
  @Input() tabs = true;
  @Input() attachments = true;
  @Input() tasks = true;

  // users
  users: any = []
  manager_name:string;
  currentUser:any

  // options list
  managersList: any = []
  fields: any;
  userFields: any;

  // status
  status: any = []
  defaultStatus: any

  // selected itens
  selectedActions: any = []
  selectedBrands: any = []
  selectedChannels: any = []
  selectedTargets: any = []
  selectedManagers: any = []

  selectedCategories: any = []

  hasOpenTasks = true;

  // part
  parts: any = []
  types: any = []

  briefing:any = [];

  // demand
  demand: any = {}
  @Input() demand_id = 0
  recurring: any = 'recurring';
  handled: any
  recurrence_interval: any
  demand_files: any;

  showMessageSaveBriefing = false

  //files
  public files: NgxFileDropEntry[] = [];
  public readonly MAX_FILE_SIZE_MB = 20;
  greaterFiles: any = [];

  formData = new FormData();

  // events
  events:any;
  event:any;

  allFilled:any;
  clickSubmit = false;
  showUsersBox = false;

  skeleton = false

  @ViewChild('history') private historyElement!: HistoryComponent;
  @ViewChild('demandModal') private demandModal!: ElementRef;

  ngAfterViewInit(): void {
    // Acessa o tamanho do modal assim que a view for inicializada
    const width = this.demandModal.nativeElement.offsetWidth;
    console.log(width);
    this.fieldSize = width < 1200 ? '50' : '33';
    console.log(this.fieldSize);
  }

  constructor(
    private formBuilder: FormBuilder,
    private demandService: DemandsService,
    private partsService: PartsService,
    private partsTypesService: PartsTypesService,
    private statusService: StatusService,
    private route: ActivatedRoute,
    private router: Router,
    private clipboard: Clipboard,
    private clientService: ClientsService,
    private fieldService: FieldService,
    private managerService: ManagersService,
    private userService: UserService,
    private modalService: NgbModal,
    private demandEventsService:DemandEventsService,
    private el: ElementRef
  ) { }

  async ngOnInit() {
    this.currentUser = this.userService.getLocalUser();
    this.getById(this.demand_id)
    this.getUsers();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['demand_id']) {
      console.log('Novo demandId recebido:', this.demand_id);
      this.getById(this.demand_id);
    }
  }

  getUserFields(user_id:number) {

    this.fieldService.getFieldsAndValuesByModel(user_id, 'user', {hierarchy: true}).subscribe({
      next: (result: any) => {
        console.log(result);
        this.userFields = result;
        console.log(this.userFields);
        if (this.userFields) {
          this.userFields.forEach(field => this.addDropdownSettingsUser(field));
        }
        // console.log(this.userFields);
      },
      error: (err) => console.log(err)
    });
  }

  hasValue(field: any): boolean {
    return field?.selecteds?.[0]?.value || (field?.children && field.children.some(this.hasValue.bind(this)));
  }

  duplicateDemand() { 
    this.showRequesterForm();
  }

  showRequesterForm() {
    // this.modalService.open(this.requesterFormModal, { windowClass: 'custom-modal', size: 'xl' });
    this.modalService.open(this.requesterFormModal, { size: 'lg', windowClass: 'requester-demand-form-size custom-modal-backdrop' });
  }

  addEvent() { 
    this.events.push({
      name: '',
      description: '',
      rrule: '',
      rrule_description: '',
      user_id: this.currentUser.id,
      demand_id: this.demand_id
    })
  }

  getById(id: any, hash: any = null) {
    this.demand_id = id
    this.skeleton = true
    this.getOptions(this.demand_id)
    
    this.demandService.getById(id).subscribe({
      next: (result: any) => {
        this.demand = result
        this.getUserFields(this.demand.user.id)
        this.briefing = this.demand.briefing;
        this.selectedManagers = result?.managers;
        this.skeleton = false
        this.demand_files = result.files;
        if (this.demand.status.identifier === 'finalizada') this.showActionsButtons = false;
        this.demandHeader.emit({ title: result.name, status: result.status, date: result.created_at, priority: result.priority })
        this.contentControl.setValue(result.content)
        this.getEmoji(this.demand.emoji_id);
      },
      error: (err) => console.log(err)
    })
  }

  getEmoji(emoji_id:number) {
    this.demand.emoji = emojis.filter((e:any) => {
      return e.id == emoji_id
    })[0]
  }

  getStatus() {
    this.statusService.getAll({ limit: 100 }).subscribe({
      next: (result: any) => this.status = result,
      error: (err) => console.log(err)
    })
  }

  getManagersList() {
    this.managerService.getAll({ limit: 100 }).subscribe({
      next: (result: any) => {
        this.managersList = result
      },
      error: (err) => console.log(err)
    })
  }

  getInputsValue() {
    let s: any = []
    this.fields.forEach((element: any) => {
      if (element.selecteds.length > 0) {
        element.selecteds.forEach((item: any) => {
          s.push(Number(item.id))
          this.formData.append("fields[]", item.id);
        });
      }
    });

    this.selectedManagers.forEach((m: any) => {
      this.formData.append('managers[]', m.id)
    });
  }

  setArea(area_id){ 
    
 }

  getUsers() {
    this.userService.getAll({
      group: ['analyst', 'screening', 'leadership'],
      area_id: this.demand.area_id
    }).subscribe({
      next: (result: any) => {
        this.users = result;
      },
      error: (err) => console.log(err)
    })
  }

  filterUser(user: any): boolean {
    if (!this.manager_name) return true; 

    return user.name.toLowerCase().includes(this.manager_name.toLowerCase());
  }

  setManager(manager: any) { 
    this.showUsersBox = false;
    console.log(manager);
    this.demand.manager = manager;
    this.demandService.setManager(this.demand.id, manager.id).subscribe({
      next: (result) => console.log(result),
      error: (err) => console.log(err)
    })
  }

  deleteManager() { 
    this.showUsersBox = false;
    this.demand.manager = [];
    this.demandService.deleteManager(this.demand.id).subscribe({
      next: (result) => console.log(result),
      error: (err) => console.log(err)
    })
  }

  evaluation() { 
    this.demandService.updateStatus({
      id: this.demand_id,
      identifier: "em_aprovacao",
    }).subscribe({
      next: (result) => this.cancel(),
      error: (err) => console.log(err)
    })
  }

  finish() { 
    this.demandService.updateStatus({
      id: this.demand_id,
      identifier: "concluida",
    }).subscribe({
      next: (result) => this.cancel(),
      error: (err) => console.log(err)
    })
  }

  save(data: any) {
    this.loading = true

    Object.keys(data.controls).forEach(key => {
      const value = data.get(key)?.value;

      // Verifica se o valor não é nulo ou indefinido
      if (value !== null && value !== undefined) {
        this.formData.append(key, value);
        console.log(this.formData.getAll(key));
      }
    })

    this.getInputsValue();

    this.demandService.save(this.formData).subscribe({
      next: (result: any) => {
        if (this.parts.length > 0) {
          this.demand_id = result.id
        }

        this.loading = false

        Swal.fire({
          position: 'top-end',
          icon: 'success',
          text: 'Demanda salva com sucesso',
          showConfirmButton: false,
          timer: 1500
        })

        // if (!this.approved) this.approved = true
        this.modal ? this.updateCalendar.emit(true) : this.router.navigateByUrl(`/demands/edit/${result.id}`)

      },
      error: (err) => {
        console.log(err)
        this.loading = false
      }
    })
  }

  cancel() {
    this.cancelModal.emit(true)
    this.router.navigateByUrl('screening/demands');
  }

  getTypes() {
    this.partsTypesService.getAll({ limit: 100 }).subscribe({
      next: (result: any) => this.types = result,
      error: (err) => console.log(err)
    })
  }

  getOptions(demand_id:number) {
    this.demandService.getFields(demand_id, 'demand', {hierarchy: true}).subscribe({
      next: (result: any) => {
        if (result) {
          result.forEach(field => this.addDropdownSettings(field));
          this.fields = result;
          console.log(this.fields);
        }
       
      },
      error: (err) => console.log(err)
    });
  }

  addDropdownSettingsUser(field:any) {
    field.dropdownSettings = {
      idField: 'id',
      textField: 'value',
      itemsShowLimit: 1,
      allowSearchFilter: false,
      enableCheckAll: false,
      showSelectedItemsAtTop: true,
      noDataAvailablePlaceholderText: 'Nenhuma opção selecionada',
      singleSelection: !field.multiple ? true : false
  };

    if (field.children && field.children.length > 0) {
        field.children.forEach(child => this.addDropdownSettingsUser(child));
    }
  }

  addDropdownSettings(field: any) {
    field.dropdownSettings = {
      idField: 'id',
      textField: 'value',
      itemsShowLimit: 1,
      allowSearchFilter: false,
      enableCheckAll: false,
      showSelectedItemsAtTop: true,
      noDataAvailablePlaceholderText: 'Nenhuma opção selecionada',
      singleSelection: !field.multiple
    };
  
    // Garantir que field.selecteds seja sempre um array ou um valor simples, dependendo de field.multiple
    if (field.selecteds) {
      if (field.multiple) {
        // Se for múltipla, sempre garantir que seja um array de IDs
        field.selecteds = Array.isArray(field.selecteds) ? field.selecteds.map(item => item.id) : [field.selecteds.id];
      } else {
        // Se não for múltipla, garantir que seja um valor simples (único ID)
        if(field.selecteds[0]) field.selecteds = field.selecteds[0].id;
      }
    }
  
    if (field.children && field.children.length > 0) {
      field.children.forEach(child => this.addDropdownSettings(child));
    }
  }

  viewText() {
    this.demandService.getText(this.demand_id).subscribe({
      next: (result: any) => {
        Swal.fire({
          title: 'Versão texto',
          width: 800,
          html: result.response,
          showConfirmButton: false,
          showCloseButton: true,
          confirmButtonText: 'ok'
        })
      }
    })
  }

  copyText() {
    this.demandService.getText(this.demand_id).subscribe({
      next: (result: any) => {
        this.clipboard.copy(result.response)
        Swal.fire({
          position: 'top-end',
          icon: 'success',
          text: 'Texto copiado com sucesso',
          showConfirmButton: false,
          timer: 1500
        })
      }
    })
  }

  copyUrlTracking() {
    this.clipboard.copy(`${URL_API}/email/access/tracking/${this.demand_id}`)
    Swal.fire({
      position: 'top-end',
      icon: 'success',
      text: 'Url de rastreamento copiada com sucesso',
      showConfirmButton: false,
      timer: 1500
    })
  }

  // setClientNavigation(hash: any) {
  //   this.clientService.getByHash(hash).subscribe({
  //     next: (result: any) => {
  //       this.clientService.setClientNavigation(result.id.toString()).subscribe({
  //         next: () => {
  //           this.router.navigateByUrl(`demands/edit/${this.demand_id}`)
  //         },
  //         error: (err) => console.log(err)
  //       })
  //     },
  //     error: (err) => console.log(err)
  //   })
  // }

  addOpacity(color: string, opacity: number): string {
    // console.log(color);
    const hex = color.replace('#', '');
    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);
  
    return `rgba(${r}, ${g}, ${b}, ${opacity})`;
  }

  addEmoji(emoji_id:any){
    this.emoji_id = emoji_id;
    this.demand.emoji_id = emoji_id
    this.emoji.emit(this.emoji_id);
    this.demandService.setEmoji(this.demand_id, {emoji_id: this.emoji_id}).subscribe({
      next: (result:any) => {
        console.log(result);
      }, error: (err:any) => console.log(err)
    })
  }

  updateBriefing(): void {
    console.log(this.briefing);

    this.showMessageSaveBriefing = true
    this.demandService.updateBriefing(this.demand_id, {briefing: this.briefing}).subscribe({
      next: (result) => {
        console.log(result);
        this.showMessageSaveBriefing = false
      },
      error: (err) => console.log(err)
    })
  }
}
