<app-default-table
    pageTitle="ÁREAS"
    tableTitle="Áreas"
    urlNewItem="/leadership/areas/new"
    textNewItem="Nova tarefa"
    editRouter="/leadership/areas/edit"
    [record]="areas"
    [skeleton]="skeleton"
    (eventSearch)="setSearch($event)"
    (eventDelete)="delete($event)"
    (eventOrder)="setOrder($event)"
    (eventPage)="setPage($event)"
    model="areas"
    [updateOrder]="true"
    (eventOrderByName)="orderBy = 'name'; get()">
</app-default-table>