<main class="main-content main-content-bg mt-0">
    <div class="page-header min-vh-100" style="background: url('../../../assets/argon-dashboard-master/assets/img/claro/bg-login.png'); background-size: cover;">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-7 col-md-7">
                    <div class="card register border-0 mb-0 bg-transparent shadow-none">
                        <div class="card-body px-lg-5 pt-2 bg-white text-center pt-6 px-6 mx-7" id="otp" *ngIf="user">
                            <img src="../../../assets/argon-dashboard-master/assets/img/claro/email-check.svg" alt="">
                            <p class="text-dark fw-bold fs-5 mt-4 mb-1">Por favor, verifique seu e-mail.</p>
                            <p class="text-dark fs-6 mb-5">Enviamos um código para <span class="fw-bold">{{user?.email}}</span></p>
                            <ngx-otp-input
                            #otpInput
                            [config]="otpConfig"
                            (otpChange)="onOtpChange($event)"
                            [ngClass]="pin.valid === false ? 'otp-invalid' : ''"
                            ></ngx-otp-input>
                            <small class="text-center fw-bold" [ngClass]="pin.valid === false ? 'text-danger' : 'text-dark'">{{pin?.message}}</small>
                            <button class="resend-button" (click)="resend()" [disabled]="resendLoading">
                                <small class="text-secondary text-underline">{{resendLoading ? 'Enviando outro código...' : 'Enviar outro código'}}</small>
                            </button>
                            <button class="btn w-100 my-4 text-white mb-2" (click)="validatePin()" [disabled]="!pin?.value" *ngIf="!pin.valid">
                                <span class="spinner-grow spinner-grow-sm" 
                                *ngIf="loading" role="status" 
                                aria-hidden="true">
                                </span>
                                {{ loading ? 'Verificando' : 'Verificar código' }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</main>