import { Injectable } from '@angular/core';
import { BaseService } from 'src/app/core/shared/services/base.service';

@Injectable({
  providedIn: 'root'
})
export class FiltersService extends BaseService {

  getAll(filter: any = null) {
    return this.post(`filters/filter`, filter);
  }

  save(context:string, filters:any) { 
    return this.post(`filters`, {context, filters: filters});
  }
}
