import { AfterContentChecked, AfterViewChecked, AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import Sortable from 'sortablejs';
import { DataService } from '../../services/data/data.service';

@Component({
  selector: 'app-default-table',
  templateUrl: './default-table.component.html',
  styleUrls: ['./default-table.component.scss']
})
export class DefaultTableComponent implements OnInit, AfterViewChecked{

  @Input() record: any = []
  @Input() skeleton = true
  @Input() tableTitle: any
  @Input() editRouter: any
  @Input() pageTitle: any
  @Input() urlNewItem: any
  @Input() textNewItem = 'Novo item'
  @Input() emptyTableText = 'Nenhum registro encontrado'
  @Input() smartmail = false
  @Input() updateOrder = false
  @Input() model = ''

  @Output() eventDelete      = new EventEmitter()
  @Output() eventPage        = new EventEmitter()
  @Output() eventOrder       = new EventEmitter()
  @Output() eventSearch      = new EventEmitter()
  @Output() eventSetTemplate = new EventEmitter()
  @Output() eventOrderByName = new EventEmitter();

  @ViewChild('sortableTable', { static: false }) sortableTable: ElementRef;
  private sortableInstance: any;

  menuTemplateActive = 0


  pagination: Array<number> = []

  constructor(
    private router: Router,
    private dataService: DataService
  ) { }

  ngOnInit(): void {
  }

  onSortEnd(event: any) {
    let newOrder = Array.from(event.from.children).map((item: any) => item.getAttribute('data-id'));
    console.log('Nova ordem:', newOrder);

    this.dataService.updateOrder(this.model ,{order: newOrder}).subscribe({
      next: (result) => {

      },
      error: (err) => console.log(err)
    })
    // Aqui você pode enviar a nova ordem para o backend, se necessário
  }

  ngAfterViewChecked(){
    if(this.pagination.length == 0) 
    for (let i = 1; i <= this.record?.last_page; i++) {
      this.pagination.push(i)
    }
    
    // Evita re-inicialização do Sortable
    if (this.updateOrder && this.sortableTable && !this.sortableInstance) {
      const el = this.sortableTable.nativeElement;

      if (el) {
        console.log("carregou o elemento sortable");
        this.sortableInstance = new Sortable(el, {
          animation: 150,
          onEnd: (event) => {
            this.onSortEnd(event);
          }
        });
      }
    }
  }

  sortByName() { 
    this.eventOrderByName.emit()
  }

  delete(r: any) {
    this.eventDelete.emit(r)
  }

  edit(r: any) {
    this.router.navigateByUrl(`${this.editRouter}/${r.id}`)
  }

  goPage(p: number) {
    this.eventPage.emit(p)
  }

  searchItens(query: string) {
    this.eventSearch.emit(query)
  }

  listOrderChanged(e:any) { 
    console.log(e);
  }

  orderItens(order: string) {
    this.eventOrder.emit(order)
  }

  setTemplate(template: number) {
    this.menuTemplateActive = template
    this.eventSetTemplate.emit(template)
  }

}
