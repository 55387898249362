<h5 class="mb-3">TAREFAS</h5>

<div class="tasks">
  <!-- Lista de tarefas -->
  <div class="tasks-list">
    <article class="task-row task-row-create position-relative"
            *ngFor="let demandTask of demandTasks; let i = index"
            [style.backgroundColor]="demandTask.status?.color ? (demandTask.status?.color + '1A') : '#343f5a1A'"
            [class.disabled]="demandTask.done">
      
      <!-- Corpo da tarefa -->
      <div class="task-body w-100 d-flex align-items-center py-2 ps-3 pe-5">

          <!-- Checkbox e avatar do usuário -->
          <div class="d-flex align-items-center">
            <input type="radio"
                   name="done-{{ demandTask?.id }}"
                   class="form-check-input me-2 cursor-pointer"
                   (change)="done(demandTask)"
                   [disabled]="demandTask.done"
                   [checked]="demandTask.done"
                   *ngIf="currentUser?.id === demandTask.user?.id"
                   aria-label="Marcar como concluído">
            
            <!-- Seleção de usuário -->
            <div class="box-users">
              <img [src]="demandTask.user?.avatar ?? 'assets/argon-dashboard-master/assets/img/default-user.png'"
                   class="avatar"
                   [class.pointer]="!demandTask.id"
                   [title]="demandTask.user?.name || 'Sem usuário'"
                   (click)="!demandTask.id ? toggleUserList(i, $event) : ''"
                   alt="Avatar do usuário">

              <!-- Lista de usuários -->
              <div class="list shadow"
                   *ngIf="activeTaskIndex === i && !demandTask.done">
                <div class="input-group">
                  <input type="text"
                         class="form-control bg-white input-search-users"
                         placeholder="Pesquisar..."
                         [(ngModel)]="query"
                         (input)="filterAnalysts()"
                         #search
                         aria-label="Pesquisar usuários">
                </div>
                <h5 class="p-2 mb-0">Envolvidos</h5>
                <div class="item d-flex p-2 align-items-center pointer"
                     *ngFor="let u of filteredAnalysts"
                     (click)="demandTask.user = u; toggleUserList(i, $event)">
                  <img [src]="u.avatar || 'assets/argon-dashboard-master/assets/img/default-user.png'"
                       class="avatar avatar-sm"
                       alt="Avatar do analista">
                  <span class="ms-2 text-xs">{{ u.name }}</span>
                </div>
              </div>
            </div>
          </div>

          <!-- Dropdown de tarefas -->
          <div class="dropdown me-2">
            <button class="btn bg-transparent shadow-none dropdown-toggle pe-0 m-0 fw-normal"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    aria-label="Selecionar tarefa">
              <span style="max-width: 230px; text-overflow: ellipsis">{{ demandTask?.task?.name || 'Selecione' }}</span>
            </button>
            <ul class="dropdown-menu">
              <li *ngFor="let t of tasks">
                <a class="dropdown-item pointer"
                   [class.bg-secondary]="demandTask.task?.id === t.id"
                   (click)="demandTask.task = t; !demandTask.new ? save(demandTask, i) : ''">
                  {{ t.name }}
                </a>
              </li>
            </ul>
          </div>

          <!-- Ações da tarefa -->
          <div class="d-flex align-items-center ms-auto">
            <!-- Timesheet -->
            <button (click)="startTimesheet(demandTask.id)"
                    class="btn-timesheet border-0 bg-transparent w-auto p-0"
                    *ngIf="!demandTask.new && demandTask?.user && demandTask.user.id === currentUser.id && ['analyst'].includes(currentUser.group.name)"
                    aria-label="Iniciar/Parar timesheet">
              <img src="assets/argon-dashboard-master/assets/img/claro/task_timesheet.svg"
                   [class.btn-blink]="demandTask?.timesheet_active"
                   width="25"
                   height="25"
                   alt="Ícone de timesheet">
              <span class="ms-2 me-4">{{ demandTask?.formatted_timesheet_sum }}</span>
            </button>

            <!-- Data de vencimento -->
            <input type="date"
                   class="form-control date bg-transparent"
                   style="width: 125px"
                   #date
                   [value]="demandTask.due_date"
                   [(ngModel)]="demandTask.due_date"
                   [max]="max"
                   aria-label="Data de vencimento">

            <!-- Exclusão -->
            <i class="fa-regular fa-circle-xmark mx-2 delete-task-btn pointer"
               *ngIf="!demandTask?.new"
               (click)="demandTask?.id ? deleteTask(demandTask?.id) : deleteFromArray(i)"
               role="button"
               aria-label="Excluir tarefa">
            </i>

            <!-- Botões para nova tarefa -->
            <ng-container *ngIf="demandTask?.new">
              <button class="btn mb-0 save-task pointer ms-auto me-2"
                      [disabled]="demandTask.done"
                      (click)="save(demandTask, i)"
                      aria-label="Salvar tarefa">
                <i class="fa-solid fa-check text-white"></i>
              </button>
              <button class="btn mb-0 remove-task pointer"
                      [disabled]="demandTask.done"
                      (click)="demandTask?.id ? deleteTask(demandTask?.id) : deleteFromArray(i)"
                      aria-label="Remover tarefa">
                <i class="fa-solid fa-x text-white"></i>
              </button>
            </ng-container>
          </div>
      </div>

      <!-- Botão de edição -->
      <button type="button"
              class="pencil-btn"
              *ngIf="!demandTask?.new && ['analyst'].includes(currentUser.group.name)"
              [style.background]="demandTask?.status?.color ?? 'dimgray'"
              (click)="demandTask.edit = !demandTask.edit"
              aria-label="Editar status">
        <i class="fa fa-pencil"></i>
      </button>

      <!-- Seleção de status -->
      <div class="select-status-box" *ngIf="demandTask.edit">
        <ul class="list-unstyled m-0">
          <li *ngFor="let s of status"
              class="text-uppercase pointer"
              style="font-weight: 500"
              [style.color]="s.color"
              [style.borderColor]="s.color"
              (click)="updateStatus(demandTask, s)">
            {{ s.name }}
          </li>
        </ul>
      </div>
    </article>
  </div>

  <!-- Botão de adicionar tarefa -->
  <button class="btn-add"
          (click)="addTask()"
          *ngIf="canAdd"
          aria-label="Adicionar nova tarefa">
    + Adicionar tarefa
  </button>
</div>