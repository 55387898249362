import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import { ClientsService } from 'src/app/shared/services/user-management/clients.service';
import { UserService } from 'src/app/shared/services/user-management/user.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  loginForm: any
  loading = false
  currentUser:any = null;
  hidePassword = true;
  client_settings:any = null;

  constructor(
    private userService: UserService,
    private formBuilder: FormBuilder,
    private service:     AuthService,
    private router:      Router,
    private auth: AuthService,
    private clientService: ClientsService
  ) { }

  ngOnInit(): void {
    this.currentUser = this.userService.getLocalUser();

    if(this.currentUser) this.router.navigateByUrl(`/${this.currentUser.group.name}`)

    this.loginForm = this.formBuilder.group({
      'email'    : ['', Validators.required],
      'password' : ['', Validators.required]
    })

    console.log(this.router.url)

    this.client_settings = this.clientService.getUserClientSettings();
    console.log(this.client_settings);
    
  }

  login(data: any) {
    this.loading = true
    this.service.login(data.value).subscribe({
      next:  (result) => {
        console.log(result);
        this.loading = false;
        this.router.navigate(['/']);
        console.log(this.auth.getRedirectUrl());
        this.router.navigateByUrl(this.auth.getRedirectUrl() || '/');
      },
      error: (err) => {
        console.log(err)
        this.loading = false;

        Swal.fire({
          icon: 'warning',
          text: err.error.message,
          confirmButtonText: 'Ok'
        }).then((result) => {
          if (result.isConfirmed && err.error.code == 400 ) {
            this.router.navigateByUrl(`otp/verification/${err.error.hashid}`)
          }
        });
      }
    });
  }

  hide() { 
    this.hidePassword = !this.hidePassword;
    console.log('hide', this.hidePassword);
  }
}
