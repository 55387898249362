import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { TaskService } from '../../services/demands/task.service';
import { StatusService } from '../../services/demands/status.service';
import { UserService } from '../../services/user-management/user.service';
import { DemandTasksService } from '../../services/demands/demand-tasks.service';
import { TimesheetService } from '../../services/demands/timesheet.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-tasks',
  templateUrl: './tasks.component.html',
  styleUrls: ['./tasks.component.scss']
})
export class TasksComponent implements OnInit {
  @Input() demand_id: number = 0;
  @Input() area_id: number = 0;
  @Input() max: any = null;
  @Input() canAdd: boolean = true;
  @Output() hasOpenTasks = new EventEmitter<boolean>();

  demandTasks: any[] = [];
  tasks: any[] = [];
  users: any[] = [];
  filteredAnalysts: any[] = [];
  status: any[] = [];
  currentUser: any;
  activeTaskIndex: number | null = null;
  query: string = '';
  skeleton: boolean = true;

  constructor(
    private demandTaskService: DemandTasksService,
    private tasksService: TaskService,
    private usersService: UserService,
    public timesheetService: TimesheetService,
    private statusService: StatusService,
    private eRef: ElementRef
  ) {}

  ngOnInit(): void {
    this.currentUser = this.usersService.getLocalUser();
    this.getDemandTasks();
    this.getUsers();
    this.getTasks();
    this.getStatus();

    this.timesheetService.endTime$.subscribe((demandTaskId) => {
      if (demandTaskId) {
        this.updateTaskAfterTimesheet(demandTaskId);
      }
    });
  }

  // Filtra analistas com base na query
  filterAnalysts(): void {
    this.filteredAnalysts = this.query
      ? this.users.filter(u => u.name.toLowerCase().includes(this.query.toLowerCase()))
      : this.users;
  }

  // Busca todos os status disponíveis
  getStatus(): void {
    this.statusService.getAll({ context: 'task' }).subscribe({
      next: (result: any) => {
        this.status = result;
      },
      error: (err) => console.error('Erro ao buscar status:', err)
    });
  }

  // Busca todas as tarefas disponíveis
  getTasks(): void {
    this.tasksService.getAll({
      area_id: this.area_id
    }).subscribe({
      next: (result: any) => {
        this.tasks = result;
        console.log(result);
      },
      error: (err) => console.error('Erro ao buscar tarefas:', err)
    });
  }

  // Verifica se há tarefas abertas e emite evento
  checkHasOpenTasks(): void {
    const hasOpenTasks = this.demandTasks.some(t => !t.done);
    this.hasOpenTasks.emit(hasOpenTasks);
  }

  // Busca tarefas relacionadas à demanda
  getDemandTasks(): void {
    this.demandTaskService.getByDemandId(this.demand_id).subscribe({
      next: (result: any) => {
        this.demandTasks = result;
        this.demandTasks.forEach(task => {
          task.timesheet_active = this.isDemandTaskTimesheetActive(task.id);
        });
        this.checkHasOpenTasks();
        this.skeleton = false;
      },
      error: (err) => console.error('Erro ao buscar demandTasks:', err)
    });
  }

  // Marca uma tarefa como concluída
  done(demandTask: any): void {
    this.demandTaskService.done(demandTask.id).subscribe({
      next: () => this.getDemandTasks(),
      error: (err) => console.error('Erro ao marcar como concluído:', err)
    });
  }

  // Busca usuários do grupo 'analyst'
  getUsers(): void {
    this.usersService.getAll({
      area_id: this.area_id,
      group: ['analyst']
    }).subscribe({
      next: (result: any) => {
        console.log(result);
        this.users = result;
        this.filteredAnalysts = this.users;
      },
      error: (err) => console.error('Erro ao buscar usuários:', err)
    });
  }

  // Adiciona uma nova tarefa ao array
  addTask(): void {
    let hasUnsavedDemandTasks = this.demandTasks.filter(t => t.new === true)[0]

    if(hasUnsavedDemandTasks) return;

    this.skeleton = true;
    const newTask = {
      demand_id: this.demand_id,
      due_date: new Date(),
      done: 0,
      timesheets_sum_duration: 0,
      new: true
    };
    this.demandTasks.push(newTask);
    this.skeleton = false;
  }

  // Verifica se o timesheet está ativo para a tarefa
  isDemandTaskTimesheetActive(demandTaskId: number): boolean {
    const timesheet = localStorage.getItem('timesheet');
    if (timesheet) {
      const parsed = JSON.parse(timesheet);
      return parsed.demand_task_id === demandTaskId;
    }
    return false;
  }

  // Inicia o timesheet para uma tarefa
  startTimesheet(demandTaskId: number): void {
    if (!localStorage.getItem('timesheet')) {
      this.timesheetService.setStartTime(demandTaskId);
      this.getDemandTaskById(demandTaskId);
    } else {
      console.log('Já existe um timesheet ativo.');
    }
  }

  // Busca uma tarefa específica por ID
  getDemandTaskById(demandTaskId: number): void {
    this.demandTaskService.getById(demandTaskId).subscribe({
      next: (result: any) => {
        result.timesheet_active = this.isDemandTaskTimesheetActive(demandTaskId);
        const index = this.demandTasks.findIndex(task => task.id === demandTaskId);
        if (index !== -1) {
          this.demandTasks[index] = result;
        }
      },
      error: (err) => console.error('Erro ao buscar tarefa por ID:', err)
    });
  }

  // Salva uma tarefa
  save(demandTask: any, index: number): void {
    console.log(demandTask);
    this.demandTaskService.save(this.demand_id, demandTask).subscribe({
      next: (result) => { 
        this.demandTasks[index] = result;
        console.log(result);
      },
      error: (err) => console.error('Erro ao salvar tarefa:', err)
    });
  }

  // Exclui uma tarefa com confirmação
  deleteTask(id: number): void {
    Swal.fire({
      icon: 'warning',
      text: 'Deseja excluir a tarefa?',
      showCancelButton: true,
      confirmButtonText: 'Excluir',
      cancelButtonText: 'Cancelar',
      confirmButtonColor: '#f5365c'
    }).then(result => {
      if (result.isConfirmed) {
        this.demandTaskService.deleteById(id).subscribe({
          next: () => this.getDemandTasks(),
          error: (err) => console.error('Erro ao excluir tarefa:', err)
        });
      }
    });
  }

  // Remove uma tarefa do array local
  deleteFromArray(index: number): void {
    Swal.fire({
      icon: 'warning',
      text: 'Deseja excluir a tarefa?',
      showCancelButton: true,
      confirmButtonText: 'Excluir',
      cancelButtonText: 'Cancelar',
      confirmButtonColor: '#f5365c'
    }).then(result => {
      if (result.isConfirmed) {
        this.demandTasks.splice(index, 1);
      }
    });
  }

  // Atualiza o status de uma tarefa
  updateStatus(task: any, status: any): void {
    this.tasksService.updateStatus({
      id: task.id,
      identifier: status.identifier,
      context: 'task'
    }).subscribe({
      next: () => this.getDemandTasks(),
      error: (err) => console.error('Erro ao atualizar status:', err)
    });
  }

  // Alterna a visibilidade da lista de usuários
  toggleUserList(index: number, event: MouseEvent): void {
    event.stopPropagation();
    this.activeTaskIndex = this.activeTaskIndex === index ? null : index;
  }

  // Fecha a lista de usuários ao clicar fora
  @HostListener('document:click', ['$event'])
  clickOut(event: Event): void {
    if (this.activeTaskIndex !== null && !this.eRef.nativeElement.contains(event.target)) {
      this.activeTaskIndex = null;
    }
  }

  // Atualiza tarefa após evento de timesheet
  private updateTaskAfterTimesheet(demandTaskId: number): void {
    this.demandTaskService.getById(demandTaskId).subscribe({
      next: (result: any) => {
        result.timesheet_active = this.isDemandTaskTimesheetActive(demandTaskId);
        const index = this.demandTasks.findIndex(task => task.id === demandTaskId);
        if (index !== -1) {
          this.demandTasks[index] = result;
        }
      },
      error: (err) => console.error('Erro ao atualizar tarefa após timesheet:', err)
    });
  }
}