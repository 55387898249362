<div class="row mt-4">
    <div class="col-lg-12 mb-4">
        <div class="card z-index-2 h-100">
            <div class="card-header pb-2 pt-3 bg-transparent">
              <div class="row align-items-center">
                <div class="col-2">
                  <h6 class="text-capitalize mb-0">{{ pageTitle }}</h6>
                </div>
                <div class="col-5 d-flex justify-content-end">
                  <input type="text" placeholder="Busque aqui" (keyup.enter)="searchItens(search.value)" #search class="form-control">
                </div>
                <div class="col-3 d-flex justify-content-end">
                  <select class="form-control" #order (change)="orderItens(order.value)">
                    <option value="desc">Mais recentes</option>
                    <option value="asc">Mais antigas</option>
                  </select>
                </div>
                <div class="col-2 d-flex justify-content-end">
                  <button class="btn btn-primary btn-sm m-0 me-1" (click)="sortByName()">Ordenar pelo nome</button>
                  <a [routerLink]="urlNewItem" class="btn btn-xs btn-primary mb-0">
                    <i class="fas fa-plus pe-2" aria-hidden="true"></i> {{ textNewItem }}
                  </a>
                </div>
              </div>
            </div>
            <div class="card-body p-3 pb-4">

                <ngx-skeleton-loader 
                    *ngIf="skeleton"
                    count="5" 
                    appearance="line" 
                    [theme]="{height: '45px'}">
                </ngx-skeleton-loader>

                <div *ngIf="!skeleton">
                  
                  <ul class="nav nav-tabs" *ngIf="smartmail">
                    <li class="nav-item">
                      <a href="javascript:;" class="nav-link" [class.active]="!menuTemplateActive" (click)="setTemplate(0)">Emails</a>
                    </li>
                    <li class="nav-item">
                      <a href="javascript:;" class="nav-link" [class.active]="menuTemplateActive" (click)="setTemplate(1)">
                        Templates
                      </a>
                    </li>
                  </ul>
                  
                  <div class="table-responsive p-0">
                      <div class="alert alert-info mt-4 text-light" role="alert" *ngIf="menuTemplateActive">
                        <p class="mb-0">Salve como template para compartilhar com outros usuários.</p>
                      </div>
                      <table class="table align-items-center mb-0" *ngIf="record?.data?.length > 0">
                        <thead>
                          <tr>
                            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">{{ tableTitle }}</th>
                            <th *ngIf="smartmail" class="w-20"></th>
                            <th class="w-20"></th>
                          </tr>
                        </thead>
                        <tbody #sortableTable>
                          <tr *ngFor="let r of record?.data"  [attr.data-id]="r.id">
                            <td>
                              <div class="d-flex px-2 py-1">
                                <div class="d-flex flex-column justify-content-center">
                                  <h6 class="mb-0 text-sm">{{ r.name }}</h6>
                                </div>
                              </div>
                            </td>
                            <td *ngIf="smartmail"><small *ngIf="r?.template" class="badge badge-sm text-primary">Template</small></td>
                            <td>
                              <button class="btn btn-primary btn-sm m-0 me-1" (click)="edit(r)">Editar</button>
                              <button class="btn btn-danger btn-sm m-0 me-1"  (click)="delete(r)">Remover</button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <small class="text-center d-block py-5" *ngIf="record?.data?.length == 0">
                        {{ emptyTableText }}
                      </small>
                  </div>
                  <nav aria-label="Page navigation example" *ngIf="record?.last_page > 1">
                      <ul class="pagination justify-content-end mt-4">
                          <li class="page-item active" 
                              *ngFor="let p of pagination"
                              [class.active]="record?.current_page == p">
                                  <a class="page-link" (click)="goPage(p)" href="javascript:;">{{ p }}</a>
                          </li>
                      </ul>
                  </nav>
                </div>

              </div>
        </div>
    </div>
</div>