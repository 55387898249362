<ng-container *ngIf="!loading">
    <router-outlet></router-outlet>
    <app-timesheet *ngIf="isUserLoggedIn()"></app-timesheet>
</ng-container>

<ng-container *ngIf="loading">
    <div id="loading-system">
        <img src="assets/argon-dashboard-master/assets/img/claro/logos/aurora_logo.png" alt="">
        <div class="loader"></div>
    </div>
</ng-container>