<div class="card">
    <div class="card-header py-3">
        <div class="row align-items-center">
            <div class="col">
                <h6 class="table-title" *ngIf="!skeleton">
                    <a [routerLink]="'/' + currentUser.group.name + '/projects'"><i class="fa-solid fa-arrow-left me-3"></i></a>
                    <span class="project-code me-2">{{project.code}}</span> 
                    <span class="project-name">{{project.name}}</span>
                </h6>
                <ngx-skeleton-loader *ngIf="skeleton" 
                        count="1" 
                        appearance="line"
                        [theme]="{ height: '26px', width: '175px', marginBottom: '8px'}">
                </ngx-skeleton-loader>
            </div>
            <div class="col-auto ps-5" style="border-left: 1px solid gray">
                <p class="mb-0 text-end"*ngIf="!skeleton"><small>Responsável: {{project.user.name}}</small></p>
                <p class="mb-0 text-end"  *ngIf="!skeleton"><small>Projeto criado em: {{project.created_at | date: 'dd/MM/yyyy'}}</small></p>
                <ngx-skeleton-loader *ngIf="skeleton" 
                        count="2" 
                        appearance="line"
                        [theme]="{ height: '26px', width: '175px', marginBottom: '0', marginTop: '0', display: 'block' }">
                </ngx-skeleton-loader>
            </div>
        </div>
    </div>
    <hr class="m-0 bg-secondary w-auto mx-3">
    <div class="card-body p-0">
        <app-demand-table 
        context="demand" 
        activeTab="all-records"
        [project_id]="project_id"
        [saveOptionsOnFilter]="false"
        title="DEMANDAS"></app-demand-table>
    </div>
</div>

