import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { FieldService } from 'src/app/shared/services/data/field.service';
import { UserService } from 'src/app/shared/services/user-management/user.service';
import { confirmPasswordValidator } from 'src/app/shared/validators/confirm-password.validator';  // Im
import Swal from 'sweetalert2';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrl: './user-profile.component.scss'
})
export class UserProfileComponent {

  user: any
  groups: any = []
  userForm: FormGroup;
  skeleton = true;
  fields:any;

  constructor(
    private userService: UserService,
    private fieldService: FieldService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {

    this.userForm = new FormGroup({
      //id: new FormControl('', [Validators.required, Validators.pattern('^[0-9]+$')]),
      name: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required,Validators.email]),
      phone: new FormControl('', [Validators.required]),
      password: new FormControl('', [Validators.minLength(6)]),  // Senha não obrigatória, mas deve ter pelo menos 6 caracteres se preenchida
      confirmPassword: new FormControl('', [Validators.minLength(6)])  // Confirmar Senha não obrigatória
    }, {
      validators: [confirmPasswordValidator('password', 'confirmPassword')]  // Aplicando validador para confirmar se as senhas batem
    });

    this.getUser()
    // this.getGroups()
  }

  addDropdownSettings(field: any) {
    field.dropdownSettings = {
      idField: 'id',
      textField: 'value',
      itemsShowLimit: 1,
      allowSearchFilter: false,
      enableCheckAll: false,
      showSelectedItemsAtTop: true,
      noDataAvailablePlaceholderText: 'Nenhuma opção selecionada',
      singleSelection: !field.multiple
    };
  
    // Garantir que field.selecteds seja sempre um array ou um valor simples, dependendo de field.multiple
    if (field.selecteds) {
      if (field.multiple) {
        // Se for múltipla, sempre garantir que seja um array de IDs
        field.selecteds = Array.isArray(field.selecteds) ? field.selecteds.map(item => item.id) : [field.selecteds.id];
      } else {
        // Se não for múltipla, garantir que seja um valor simples (único ID)
        if(field.selecteds[0]) field.selecteds = field.selecteds[0].id;
      }
    }
  
    if (field.children && field.children.length > 0) {
      field.children.forEach(child => this.addDropdownSettings(child));
    }
  }

  // getUser() {
    // this.route.params.subscribe((data: any) => {
    //   this.userService.getByHashId(data.hashid).subscribe({
    //     next: (result) => {
    //       this.user = result
    //     },
    //     error: (err) => console.log(err)
    //   })
    // })
  // }


  getUser(){

    let hashid: any = this.route.snapshot.paramMap.get('hashid');

    if(hashid) {

      this.userService.getByHashId(hashid).subscribe({
        next: (result) => {
          this.user = result;
          this.userForm.patchValue(this.user);
          
          console.log(this.user);

          this.getOptions(this.user.id);

          this.skeleton = false;
        },
        error: (err) => console.log(err)
      })

    } else {

      let user_id = this.userService.getLocalUser().id;
      this.userService.getById(user_id).subscribe({
        next: (result) => {
          this.user = result;
          
          this.getOptions(this.user.id);
          console.log(this.user);
          console.log(this.user.group);

          this.userForm.patchValue(this.user);
          this.skeleton = false;
        },
        error: (err) => console.log(err)
      })

    }

    console.log(this.user);
    
    
  }


  getOptions(user_id:number) {
    console.log("BUSCANDO FIELDS")
    this.userService.getFields(user_id, 'user', {hierarchy: true}).subscribe({
      next: (result: any) => {
        if (result) {
          result.forEach(field => this.addDropdownSettings(field));
          this.fields = result;
          console.log(this.fields);
        }
       
      },
      error: (err) => console.log(err)
    });
  }

  clearForm() {
   
    this.userForm.get('password')?.reset();
    this.userForm.get('confirmPassword')?.reset();
    this.userForm.markAsPristine();
    this.userForm.markAsUntouched();
    
    let user_id = this.userService.getLocalUser().id;
    this.userService.getById(user_id).subscribe({
      next: (result) => {
        this.user = result;
        this.skeleton = false;
        localStorage.setItem('user', JSON.stringify(this.user));
        console.log(this.user);
      },
      error: (err) => console.log(err)
    })
  }
  
  onSubmit(): void {

    if (this.userForm.valid) {

      let formData = new FormData();
      formData.append("id", this.user.id);
      
      // Adicionando os campos do formulário ao FormData
      Object.keys(this.userForm.value).forEach(key => {
        formData.append(key, this.userForm.value[key]);
      });
      
      this.userService.updateUser(formData).subscribe({
        next: (result) => {
          
          Swal.fire({
            position: 'center',
            icon: 'success',
            text: 'Alteração efetuada com sucesso!',
            showConfirmButton: false,
            timer: 1500
          })

          this.clearForm();

        },
        error: (err) => console.log(err)
      })

    } else {

      console.log('Formulário inválido');
      
    }
  
  }

  // getGroups() {
  //   this.userService.getAllGroups().subscribe({
  //     next: (result) => {
  //       this.groups = result
  //       console.log(result)
  //     },
  //     error: (err) => console.log(err)
  //   })
  // }

  // updateUserGroup(group_id: any) {
  //   this.user.group_id = group_id
  //   this.userService.save(this.user).subscribe({
  //     next: (result) => {
  //       Swal.fire({
  //         position: "bottom-end",
  //         icon: "success",
  //         text: "Grupo atualizado com sucesso!",
  //         showConfirmButton: false,
  //         timer: 1000
  //       });
  //     },
  //     error: (err) => console.log(err)
  //   })
  // }
}
