import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild, AfterViewInit } from '@angular/core';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { DemandsService } from 'src/app/shared/services/demands/demands.service';
import { StatusService } from 'src/app/shared/services/demands/status.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import emojis from '../../../../assets/argon-dashboard-master/assets/img/claro/icons/emojis/emojis.json';
import { UserService } from '../../services/user-management/user.service';
import { SidebarFilterComponent } from '../sidebar-filter/sidebar-filter.component';

@Component({
  selector: 'app-box-kanban',
  templateUrl: './box-kanban.component.html',
  styleUrls: ['./box-kanban.component.scss']
})
export class BoxKanbanComponent implements OnInit, AfterViewInit {
  @Input() title = 'Demandas';
  @Input() showFiltersBox = false;
  @Input() addNew = true;
  @Input() activeTab: 'demand-status' | 'task-status' | 'task-type' | 'involved-users' = 'demand-status';
  @Input() openDemandPopup = true;
  @Output() updateBoxStatus = new EventEmitter();

  currentUser: any = null;

  // Filters
  query: string = '';
  demandsCount = 0;
  skeleton = true;
  status: any;
  demand_id: number = 0;
  header: any;

  filters: any = {}; // Filtros ajustados para envio ao backend
  originalFilters: any = {}; // Filtros originais do sidebar

  @ViewChild('kanbanContent', { static: false }) kanbanContent!: ElementRef;
  @ViewChild('sideBarFilter') private sideBarFilter!: SidebarFilterComponent;

  private isDown = false;
  private startX = 0;
  private scrollLeft = 0;

  constructor(
    private demandService: DemandsService,
    private statusService: StatusService,
    private userService: UserService,
    private modalService: NgbModal
  ) {}

  ngOnInit(): void {
    this.currentUser = this.userService.getLocalUser();
  }

  ngAfterViewInit(): void {
    if (!this.kanbanContent) {
      console.error('Erro: kanbanContent não foi inicializado corretamente.');
    }
    this.sideBarFilter.filtersLoaded.subscribe((filters: any) => {
      this.originalFilters = { ...filters }; // Armazenar filtros originais
      this.applyTabRules(); // Aplicar regras das abas (se houver)
      this.getKanban();
    });
  }

  @HostListener('mousedown', ['$event'])
  onMouseDown(event: MouseEvent) {
    if (!this.kanbanContent?.nativeElement) return;
    this.isDown = true;
    this.startX = event.pageX - this.kanbanContent.nativeElement.offsetLeft;
    this.scrollLeft = this.kanbanContent.nativeElement.scrollLeft;
  }

  @HostListener('mouseleave')
  @HostListener('mouseup')
  onMouseUp() {
    this.isDown = false;
  }

  @HostListener('mousemove', ['$event'])
  onMouseMove(event: MouseEvent) {
    if (!this.isDown || !this.kanbanContent?.nativeElement) return;
    event.preventDefault();
    const x = event.pageX - this.kanbanContent.nativeElement.offsetLeft;
    const walk = (x - this.startX) * 2; // Ajuste de velocidade
    this.kanbanContent.nativeElement.scrollLeft = this.scrollLeft - walk;
  }

  getKanban(activeTab: 'demand-status' | 'task-status' | 'task-type' | 'involved-users' = this.activeTab) {
    this.activeTab = activeTab;
    this.skeleton = true;
    this.applyTabRules();

    // Adicionar a query de pesquisa, se existir
    if (this.query) {
      this.filters.query = this.query;
    } else {
      delete this.filters.query;
    }

    this.statusService.getKanban(this.activeTab, this.filters).subscribe({
      next: (result) => {
        this.status = result;
        console.log(status);
        this.demandsCount = this.status?.reduce((total, s) => total + (s.demands?.length || 0), 0);
        this.skeleton = false;
      },
      error: (err) => {
        console.error('Erro ao carregar kanban:', err);
        this.skeleton = false;
      }
    });
  }

  private applyTabRules(): void {
    // Começar com os filtros originais do sidebar
    this.filters = { ...this.originalFilters };

    // Aqui, as abas atuais ('involved-users', 'demand-status', etc.) não impõem restrições adicionais
    // como "Minhas Demandas" fazia no DemandTableComponent. Todas respeitam os filtros laterais.
    // Se no futuro houver uma aba como "Minhas Demandas", podemos adicionar uma regra específica:
    /*
    if (this.activeTab === 'my-demands') { // Exemplo futuro
      this.filters.requester_id = [this.currentUser.id];
    }
    */
  }

  resetFilters() {
    this.query = '';
    this.filters = {};
    this.originalFilters = {};
    this.activeTab = 'demand-status';
    this.getKanban();
  }

  getEmoji(emoji_id: number): string | null {
    if (!emoji_id) return null;
    const emoji = emojis.find((e: any) => e.id === emoji_id);
    return emoji?.emoji ?? null;
  }

  blockColumnTransfer(): boolean {
    return false;
  }

  drop(event: CdkDragDrop<string[]>, status: any) {
    // Desativado por enquanto (return no início da função original)
    return;

    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
      this.demandService.updateStatus({ id: event.item.data.id, status_id: status.id, activeTab: status.activeTab }).subscribe({
        next: () => this.updateBoxStatus.emit(true),
        error: (err) => console.error('Erro ao atualizar status:', err)
      });
    }
  }

  onColumnDrop(event: CdkDragDrop<any[]>) {
    moveItemInArray(this.status, event.previousIndex, event.currentIndex);
    const ids = this.status.map((item: any) => item.id);
    this.statusService.setNewOrder({ array_ids: ids }).subscribe({
      next: () => {},
      error: (err) => console.error('Erro ao reordenar colunas:', err)
    });
  }

  showDemand(demand_id: number, content: any) {
    this.demand_id = demand_id;
    this.modalService.open(content, { windowClass: 'custom-modal', size: 'xl' });
  }

  newDemand(content: any) {
    this.modalService.open(content, { size: 'lg' });
  }

  demandHeader(data: any) {
    this.header = data;
  }
}