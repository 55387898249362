<div class="card z-index-2 h-100 position-relative">
  <!-- Botões de seleção -->
  <div class="demand-tab-selectors">
    <button *ngIf="['analyst'].includes(currentUser?.group.name)" 
            (click)="getRecords('task', 'my-records')" 
            [class.selected]="activeTab === 'my-records' && context === 'task'"
            class="selector-btn">
      <span>
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="20" viewBox="0 0 18 20" fill="none">
          <path d="M11.7692 20H6.23077C4.87662 20 3.89908 20 3.09877 19.8912C2.15723 19.7628 1.512 19.4809 1.01354 18.9785C0.512308 18.4733 0.232615 17.8258 0.108 16.8769C0 16.0703 0 15.0851 0 13.7203V8.13843C0 6.77365 0 5.78845 0.108 4.98186C0.235385 4.03293 0.515077 3.38264 1.01354 2.88027C1.94123 1.9453 3.25385 1.87273 5.30308 1.85878L5.31138 3.25426C3.38954 3.26542 2.50338 3.35194 1.99385 3.86548C1.38462 4.47949 1.38462 5.70193 1.38462 8.13564V13.7176C1.38462 16.1541 1.38462 17.3765 1.99385 17.9877C2.60308 18.6017 3.816 18.6017 6.23077 18.6017H11.7692C14.1868 18.6017 15.3997 18.6017 16.0062 17.9877C16.6154 17.3737 16.6154 16.1513 16.6154 13.7176V8.13564C16.6154 5.69913 16.6154 4.4767 16.0062 3.86548C15.4966 3.35194 14.6105 3.26542 12.6886 3.25426L12.6969 1.85878C14.7489 1.86994 16.0588 1.9453 16.9865 2.88027C17.4877 3.38543 17.7674 4.03293 17.892 4.98186C18 5.78845 18 6.77365 18 8.13843V13.7203C18 15.0851 18 16.0703 17.892 16.8769C17.7646 17.8258 17.4849 18.4761 16.9865 18.9785C16.488 19.4809 15.8428 19.7656 14.9012 19.8912C14.1009 20 13.1234 20 11.7692 20Z"/>
          <path d="M13.6163 12.5593H7.61538C7.23323 12.5593 6.92308 12.2467 6.92308 11.8616C6.92308 11.4764 7.23323 11.1638 7.61538 11.1638H13.6163C13.9985 11.1638 14.3086 11.4764 14.3086 11.8616C14.3086 12.2467 13.9985 12.5593 13.6163 12.5593Z"/>
          <path d="M4.84615 12.5593H4.38369C4.00154 12.5593 3.69138 12.2467 3.69138 11.8616C3.69138 11.4764 4.00154 11.1638 4.38369 11.1638H4.84615C5.22831 11.1638 5.53846 11.4764 5.53846 11.8616C5.53846 12.2467 5.22831 12.5593 4.84615 12.5593Z"/>
          <path d="M4.84615 9.30226H4.38369C4.00154 9.30226 3.69138 8.98967 3.69138 8.60452C3.69138 8.21937 4.00154 7.90678 4.38369 7.90678H4.84615C5.22831 7.90678 5.53846 8.21937 5.53846 8.60452C5.53846 8.98967 5.22831 9.30226 4.84615 9.30226Z"/>
          <path d="M4.84615 15.8136H4.38369C4.00154 15.8136 3.69138 15.501 3.69138 15.1158C3.69138 14.7307 4.00154 14.4181 4.38369 14.4181H4.84615C5.22831 14.4181 5.53846 14.7307 5.53846 15.1158C5.53846 15.501 5.22831 15.8136 4.84615 15.8136Z"/>
          <path d="M13.6163 9.30226H7.61538C7.23323 9.30226 6.92308 8.98967 6.92308 8.60452C6.92308 8.21937 7.23323 7.90678 7.61538 7.90678H13.6163C13.9985 7.90678 14.3086 8.21937 14.3086 8.60452C14.3086 8.98967 13.9985 9.30226 13.6163 9.30226Z"/>
          <path d="M13.6163 15.8136H7.61538C7.23323 15.8136 6.92308 15.501 6.92308 15.1158C6.92308 14.7307 7.23323 14.4181 7.61538 14.4181H13.6163C13.9985 14.4181 14.3086 14.7307 14.3086 15.1158C14.3086 15.501 13.9985 15.8136 13.6163 15.8136Z"/>
          <path d="M11.3068 5.11582H6.69323C5.54677 5.11582 4.61631 4.17806 4.61631 3.02261V2.09322C4.61631 0.937762 5.54677 0 6.69323 0H11.3095C12.456 0 13.3865 0.937762 13.3865 2.09322V3.02261C13.3837 4.17806 12.4532 5.11582 11.3068 5.11582ZM6.69323 1.39548C6.31108 1.39548 6.00092 1.70807 6.00092 2.09322V3.02261C6.00092 3.40776 6.31108 3.72035 6.69323 3.72035H11.3095C11.6917 3.72035 12.0018 3.40776 12.0018 3.02261V2.09322C12.0018 1.70807 11.6917 1.39548 11.3095 1.39548H6.69323Z"/>
        </svg>
      </span> Minhas tarefas
    </button>
    <button (click)="getRecords('demand', 'my-records')" 
            [class.selected]="activeTab === 'my-records' && context === 'demand'"
            class="selector-btn">
      <span>
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="20" viewBox="0 0 18 20" fill="none">
          <path d="M11.7692 20H6.23077C4.87662 20 3.89908 20 3.09877 19.8912C2.15723 19.7628 1.512 19.4809 1.01354 18.9785C0.512308 18.4733 0.232615 17.8258 0.108 16.8769C0 16.0703 0 15.0851 0 13.7203V8.13843C0 6.77365 0 5.78845 0.108 4.98186C0.235385 4.03293 0.515077 3.38264 1.01354 2.88027C1.94123 1.9453 3.25385 1.87273 5.30308 1.85878L5.31138 3.25426C3.38954 3.26542 2.50338 3.35194 1.99385 3.86548C1.38462 4.47949 1.38462 5.70193 1.38462 8.13564V13.7176C1.38462 16.1541 1.38462 17.3765 1.99385 17.9877C2.60308 18.6017 3.816 18.6017 6.23077 18.6017H11.7692C14.1868 18.6017 15.3997 18.6017 16.0062 17.9877C16.6154 17.3737 16.6154 16.1513 16.6154 13.7176V8.13564C16.6154 5.69913 16.6154 4.4767 16.0062 3.86548C15.4966 3.35194 14.6105 3.26542 12.6886 3.25426L12.6969 1.85878C14.7489 1.86994 16.0588 1.9453 16.9865 2.88027C17.4877 3.38543 17.7674 4.03293 17.892 4.98186C18 5.78845 18 6.77365 18 8.13843V13.7203C18 15.0851 18 16.0703 17.892 16.8769C17.7646 17.8258 17.4849 18.4761 16.9865 18.9785C16.488 19.4809 15.8428 19.7656 14.9012 19.8912C14.1009 20 13.1234 20 11.7692 20Z"/>
          <path d="M13.6163 12.5593H7.61538C7.23323 12.5593 6.92308 12.2467 6.92308 11.8616C6.92308 11.4764 7.23323 11.1638 7.61538 11.1638H13.6163C13.9985 11.1638 14.3086 11.4764 14.3086 11.8616C14.3086 12.2467 13.9985 12.5593 13.6163 12.5593Z"/>
          <path d="M4.84615 12.5593H4.38369C4.00154 12.5593 3.69138 12.2467 3.69138 11.8616C3.69138 11.4764 4.00154 11.1638 4.38369 11.1638H4.84615C5.22831 11.1638 5.53846 11.4764 5.53846 11.8616C5.53846 12.2467 5.22831 12.5593 4.84615 12.5593Z"/>
          <path d="M4.84615 9.30226H4.38369C4.00154 9.30226 3.69138 8.98967 3.69138 8.60452C3.69138 8.21937 4.00154 7.90678 4.38369 7.90678H4.84615C5.22831 7.90678 5.53846 8.21937 5.53846 8.60452C5.53846 8.98967 5.22831 9.30226 4.84615 9.30226Z"/>
          <path d="M4.84615 15.8136H4.38369C4.00154 15.8136 3.69138 15.501 3.69138 15.1158C3.69138 14.7307 4.00154 14.4181 4.38369 14.4181H4.84615C5.22831 14.4181 5.53846 14.7307 5.53846 15.1158C5.53846 15.501 5.22831 15.8136 4.84615 15.8136Z"/>
          <path d="M13.6163 9.30226H7.61538C7.23323 9.30226 6.92308 8.98967 6.92308 8.60452C6.92308 8.21937 7.23323 7.90678 7.61538 7.90678H13.6163C13.9985 7.90678 14.3086 8.21937 14.3086 8.60452C14.3086 8.98967 13.9985 9.30226 13.6163 9.30226Z"/>
          <path d="M13.6163 15.8136H7.61538C7.23323 15.8136 6.92308 15.501 6.92308 15.1158C6.92308 14.7307 7.23323 14.4181 7.61538 14.4181H13.6163C13.9985 14.4181 14.3086 14.7307 14.3086 15.1158C14.3086 15.501 13.9985 15.8136 13.6163 15.8136Z"/>
          <path d="M11.3068 5.11582H6.69323C5.54677 5.11582 4.61631 4.17806 4.61631 3.02261V2.09322C4.61631 0.937762 5.54677 0 6.69323 0H11.3095C12.456 0 13.3865 0.937762 13.3865 2.09322V3.02261C13.3837 4.17806 12.4532 5.11582 11.3068 5.11582ZM6.69323 1.39548C6.31108 1.39548 6.00092 1.70807 6.00092 2.09322V3.02261C6.00092 3.40776 6.31108 3.72035 6.69323 3.72035H11.3095C11.6917 3.72035 12.0018 3.40776 12.0018 3.02261V2.09322C12.0018 1.70807 11.6917 1.39548 11.3095 1.39548H6.69323Z"/>
        </svg>
      </span> Minhas demandas
    </button>
    <button (click)="getRecords('demand', 'all-records')" 
            *ngIf="!['requester'].includes(currentUser?.group.name)"
            [class.selected]="activeTab === 'all-records'"
            class="selector-btn">
      <span>
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="20" viewBox="0 0 18 20" fill="none">
          <path d="M11.7692 20H6.23077C4.87662 20 3.89908 20 3.09877 19.8912C2.15723 19.7628 1.512 19.4809 1.01354 18.9785C0.512308 18.4733 0.232615 17.8258 0.108 16.8769C0 16.0703 0 15.0851 0 13.7203V8.13843C0 6.77365 0 5.78845 0.108 4.98186C0.235385 4.03293 0.515077 3.38264 1.01354 2.88027C1.94123 1.9453 3.25385 1.87273 5.30308 1.85878L5.31138 3.25426C3.38954 3.26542 2.50338 3.35194 1.99385 3.86548C1.38462 4.47949 1.38462 5.70193 1.38462 8.13564V13.7176C1.38462 16.1541 1.38462 17.3765 1.99385 17.9877C2.60308 18.6017 3.816 18.6017 6.23077 18.6017H11.7692C14.1868 18.6017 15.3997 18.6017 16.0062 17.9877C16.6154 17.3737 16.6154 16.1513 16.6154 13.7176V8.13564C16.6154 5.69913 16.6154 4.4767 16.0062 3.86548C15.4966 3.35194 14.6105 3.26542 12.6886 3.25426L12.6969 1.85878C14.7489 1.86994 16.0588 1.9453 16.9865 2.88027C17.4877 3.38543 17.7674 4.03293 17.892 4.98186C18 5.78845 18 6.77365 18 8.13843V13.7203C18 15.0851 18 16.0703 17.892 16.8769C17.7646 17.8258 17.4849 18.4761 16.9865 18.9785C16.488 19.4809 15.8428 19.7656 14.9012 19.8912C14.1009 20 13.1234 20 11.7692 20Z"/>
          <path d="M13.6163 12.5593H7.61538C7.23323 12.5593 6.92308 12.2467 6.92308 11.8616C6.92308 11.4764 7.23323 11.1638 7.61538 11.1638H13.6163C13.9985 11.1638 14.3086 11.4764 14.3086 11.8616C14.3086 12.2467 13.9985 12.5593 13.6163 12.5593Z"/>
          <path d="M4.84615 12.5593H4.38369C4.00154 12.5593 3.69138 12.2467 3.69138 11.8616C3.69138 11.4764 4.00154 11.1638 4.38369 11.1638H4.84615C5.22831 11.1638 5.53846 11.4764 5.53846 11.8616C5.53846 12.2467 5.22831 12.5593 4.84615 12.5593Z"/>
          <path d="M4.84615 9.30226H4.38369C4.00154 9.30226 3.69138 8.98967 3.69138 8.60452C3.69138 8.21937 4.00154 7.90678 4.38369 7.90678H4.84615C5.22831 7.90678 5.53846 8.21937 5.53846 8.60452C5.53846 8.98967 5.22831 9.30226 4.84615 9.30226Z"/>
          <path d="M4.84615 15.8136H4.38369C4.00154 15.8136 3.69138 15.501 3.69138 15.1158C3.69138 14.7307 4.00154 14.4181 4.38369 14.4181H4.84615C5.22831 14.4181 5.53846 14.7307 5.53846 15.1158C5.53846 15.501 5.22831 15.8136 4.84615 15.8136Z"/>
          <path d="M13.6163 9.30226H7.61538C7.23323 9.30226 6.92308 8.98967 6.92308 8.60452C6.92308 8.21937 7.23323 7.90678 7.61538 7.90678H13.6163C13.9985 7.90678 14.3086 8.21937 14.3086 8.60452C14.3086 8.98967 13.9985 9.30226 13.6163 9.30226Z"/>
          <path d="M13.6163 15.8136H7.61538C7.23323 15.8136 6.92308 15.501 6.92308 15.1158C6.92308 14.7307 7.23323 14.4181 7.61538 14.4181H13.6163C13.9985 14.4181 14.3086 14.7307 14.3086 15.1158C14.3086 15.501 13.9985 15.8136 13.6163 15.8136Z"/>
          <path d="M11.3068 5.11582H6.69323C5.54677 5.11582 4.61631 4.17806 4.61631 3.02261V2.09322C4.61631 0.937762 5.54677 0 6.69323 0H11.3095C12.456 0 13.3865 0.937762 13.3865 2.09322V3.02261C13.3837 4.17806 12.4532 5.11582 11.3068 5.11582ZM6.69323 1.39548C6.31108 1.39548 6.00092 1.70807 6.00092 2.09322V3.02261C6.00092 3.40776 6.31108 3.72035 6.69323 3.72035H11.3095C11.6917 3.72035 12.0018 3.40776 12.0018 3.02261V2.09322C12.0018 1.70807 11.6917 1.39548 11.3095 1.39548H6.69323Z"/>
        </svg>
      </span> Todas as demandas
    </button>
  </div>

  <!-- Cabeçalho do card -->
  <div class="card-header py-3 bg-transparent table-filter d-flex align-items-center">
    <h6 class="table-title mb-0" *ngIf="!skeleton && context === 'demand'">Demandas ({{ demands?.total }})</h6>
    <h6 class="table-title mb-0" *ngIf="!skeleton && context === 'task'">Tarefas ({{ demandsTasks?.total }})</h6>
    <ngx-skeleton-loader *ngIf="skeleton" 
                        count="1" 
                        appearance="line"
                        [theme]="{ height: '20px', width: '175px', marginBottom: '0', marginTop: '9px' }">
    </ngx-skeleton-loader>
    <button class="btn btn-primary ms-3 mb-0 border-radius-4 add-new" 
            (click)="newDemand(create_demand)">
      + Nova Demanda
    </button>

    <!-- Filtros -->
     
    <ng-select 
    [items]="itemsPerPageOptions" 
    [(ngModel)]="paginate"
    [clearable]="false"
    style="width: 64px"
    class="ms-auto"
    (change)="getRecords()">
    </ng-select>
    <div class="filters ms-2">
      <button class="btn-filters btn-filters-square me-2 border-radius-8" 
              (click)="sideBarFilter.showSideBarFilter = !sideBarFilter.showSideBarFilter" 
              [class.show]="showFiltersBox">
        <img src="assets/argon-dashboard-master/assets/img/claro/icons/filters.svg" alt="Filtros">
      </button>
    </div>
  </div>

  <hr class="m-0 bg-secondary w-auto mx-3">

  <!-- Corpo do card -->
  <div class="card-body p-3 pb-4">
    <div class="table-responsive p-0" *ngIf="demands">
      <ngx-skeleton-loader *ngIf="skeleton" 
                          count="5" 
                          appearance="line" 
                          [theme]="{ height: '45px' }">
      </ngx-skeleton-loader>

      <!-- Tabela de demandas -->
      <table class="table demand align-items-center mb-0" 
             *ngIf="demands?.data?.length > 0 && context === 'demand' && !skeleton">
        <thead>
          <tr>
            <th *ngFor="let column of demandsTableColumns" 
                class="text-center w-60" 
                (click)="sortBy(column.field)"
                [ngClass]="{
                  'asc': sortColumn === column.field && sortDirection === 'asc',
                  'desc': sortColumn === column.field && sortDirection === 'desc'
                }">
              {{ column.label }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let d of demands.data; let i = index">
            <td>
              <div class="demand d-flex align-items-center px-2 py-1">
                <span class="emoji me-2" *ngIf="d.emoji_id && !['requester'].includes(currentUser.group.name)">
                  {{ getEmoji(d.emoji_id) }}
                </span>
                <h6 class="mb-0 text-sm py-2 pointer demand-name text-underline-hover" 
                    (click)="showDemand(d.id, content)">
                  <p class="title">
                    <span class="project-code me-2" *ngIf="d.project?.code && d?.item_id">
                      {{ d.project?.code }}.{{ d?.item_id }}
                    </span>
                    <span class="demand-name">{{ d.name }}</span>
                    <span class="project-name d-block" *ngIf="d.project?.name">{{ d.project?.name }}</span>
                  </p>
                </h6>
              </div>
            </td>
            <td class="align-middle text-center">{{ d.deadline | date: "dd/MM/yyyy" }}</td>
            <td class="align-middle text-center">
              <span>
                <img src="assets/argon-dashboard-master/assets/img/claro/icons/tasks_list.svg" alt="Tarefas" width="20" class="me-2">
                {{ d.tasks_count }}
              </span>
            </td>
            <td class="align-middle text-center text-sm">
              <div class="avatar-group ms-auto">
                <div class="avatar avatar-lg avatar-sm rounded-circle" *ngFor="let u of d.involved_users">
                  <img [alt]="u?.name" [title]="u?.name" [ngbTooltip]="u?.name" 
                       [src]="u?.avatar ?? 'assets/argon-dashboard-master/assets/img/default-user.png'">
                </div>
              </div>
            </td>
            <td class="align-middle text-center text-sm">
              <div class="badge p-0 bg-white">
                <span class="badge-status" 
                      [ngStyle]="{'background-color': addOpacity(d.status?.color, 0.1), 'color': d.status?.color}">
                  {{ d.status.name }}
                </span>
              </div>
            </td>
            <td class="align-middle text-center position-relative">
              <button class="btn btn-options mb-0" (click)="toggleOptions(i)">
                <i class="fa fa-ellipsis-v text-xs" aria-hidden="true"></i>
              </button>
              <ul class="options-box" [class.d-none]="selectedRowIndex !== i">
                <li (click)="showDemand(d.id, content)">
                  <span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="15" viewBox="0 0 14 15" fill="none">
                      <path d="M8.51242 2.91162L0.928257 10.5044C0.890156 10.5428 0.86242 10.591 0.849533 10.6429L0.00877784 14.0207C-0.0164364 14.1225 0.0135405 14.2305 0.0875023 14.3048C0.143534 14.3609 0.220017 14.392 0.298181 14.392C0.321995 14.392 0.346649 14.3889 0.370462 14.3831L3.74441 13.5414C3.79708 13.5282 3.8447 13.5007 3.88281 13.4626L11.4678 5.87041L8.51242 2.91162Z" fill="white"/>
                      <path d="M13.3468 1.87646L12.5027 1.03145C11.9385 0.466613 10.9551 0.467174 10.3914 1.03145L9.35738 2.06661L12.3128 5.02512L13.3468 3.98996C13.6287 3.70783 13.7839 3.33258 13.7839 2.93321C13.7839 2.53384 13.6287 2.1586 13.3468 1.87646Z" fill="white"/>
                    </svg>
                  </span> Editar</li>
                <li (click)="showDemand(d.id, content)">
                <span>
                  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="19" viewBox="0 0 18 19" fill="none">
                    <path d="M9 3.38281C4.90909 3.38281 1.41545 5.91941 0 9.5C1.41545 13.0806 4.90909 15.6172 9 15.6172C13.095 15.6172 16.5845 13.0806 18 9.5C16.5845 5.91941 13.095 3.38281 9 3.38281ZM9 13.5781C6.74182 13.5781 4.90909 11.7511 4.90909 9.5C4.90909 7.24887 6.74182 5.42188 9 5.42188C11.2582 5.42188 13.0909 7.24887 13.0909 9.5C13.0909 11.7511 11.2582 13.5781 9 13.5781ZM9 7.05313C7.64591 7.05313 6.54545 8.15014 6.54545 9.5C6.54545 10.8499 7.64591 11.9469 9 11.9469C10.3541 11.9469 11.4545 10.8499 11.4545 9.5C11.4545 8.15014 10.3541 7.05313 9 7.05313Z" fill="white"/>
                  </svg>
                </span> Visualizar</li>
                <li (click)="deleteDemand(d.id)">
                <span>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
                    <path d="M15.1923 2.61538H11.9234V1.96154C11.9228 1.44149 11.7159 0.942903 11.3482 0.575171C10.9805 0.207438 10.4819 0.000587961 9.96182 0H6.03874C5.51869 0.000587961 5.02011 0.207438 4.65238 0.575171C4.28464 0.942903 4.07779 1.44149 4.07721 1.96154V2.61538H0.807655C0.634244 2.61538 0.467936 2.68427 0.345316 2.80689C0.222696 2.92951 0.153809 3.09582 0.153809 3.26923C0.153809 3.44264 0.222696 3.60895 0.345316 3.73157C0.467936 3.85419 0.634244 3.92308 0.807655 3.92308H1.4615V15.6923C1.4619 16.039 1.5998 16.3714 1.84495 16.6165C2.09011 16.8617 2.42249 16.9996 2.76919 17H13.2307C13.5774 16.9996 13.9098 16.8617 14.155 16.6165C14.4001 16.3714 14.538 16.039 14.5384 15.6923V3.92308H15.1923C15.3657 3.92308 15.532 3.85419 15.6546 3.73157C15.7772 3.60895 15.8461 3.44264 15.8461 3.26923C15.8461 3.09582 15.7772 2.92951 15.6546 2.80689C15.532 2.68427 15.3657 2.61538 15.1923 2.61538ZM6.69259 12.4231C6.69259 12.5965 6.6237 12.7628 6.50108 12.8854C6.37846 13.008 6.21215 13.0769 6.03874 13.0769C5.86533 13.0769 5.69903 13.008 5.57641 12.8854C5.45379 12.7628 5.3849 12.5965 5.3849 12.4231V7.19231C5.3849 7.0189 5.45379 6.85259 5.57641 6.72997C5.69903 6.60735 5.86533 6.53846 6.03874 6.53846C6.21215 6.53846 6.37846 6.60735 6.50108 6.72997C6.6237 6.85259 6.69259 7.0189 6.69259 7.19231V12.4231ZM10.6157 12.4231C10.6157 12.5965 10.5468 12.7628 10.4242 12.8854C10.3015 13.008 10.1352 13.0769 9.96182 13.0769C9.78841 13.0769 9.6221 13.008 9.49948 12.8854C9.37686 12.7628 9.30797 12.5965 9.30797 12.4231V7.19231C9.30797 7.0189 9.37686 6.85259 9.49948 6.72997C9.6221 6.60735 9.78841 6.53846 9.96182 6.53846C10.1352 6.53846 10.3015 6.60735 10.4242 6.72997C10.5468 6.85259 10.6157 7.0189 10.6157 7.19231V12.4231ZM10.6157 2.61538H5.3849V1.96154C5.3851 1.78819 5.45405 1.622 5.57662 1.49942C5.6992 1.37684 5.86539 1.30789 6.03874 1.30769H9.96182C10.1352 1.30789 10.3014 1.37684 10.4239 1.49942C10.5465 1.622 10.6155 1.78819 10.6157 1.96154V2.61538Z" fill="white"/>
                  </svg>
                </span> Excluir</li>
              </ul>
            </td>
          </tr>
        </tbody>
      </table>

      <!-- Tabela de tarefas -->
      <table class="table demand-task align-items-center mb-0" 
             *ngIf="demandsTasks?.data?.length > 0 && context === 'task' && !skeleton">
        <thead>
          <tr>
            <th *ngFor="let column of demandTasksTableColumns" 
                class="text-center {{ column.class }}"
                (click)="sortBy(column.field)"
                [ngClass]="{
                  'asc': sortColumn === column.field && sortDirection === 'asc',
                  'desc': sortColumn === column.field && sortDirection === 'desc'
                }">
              {{ column.label }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let demandTask of demandsTasks.data; let i = index" class="pointer">
            <td>
              <div class="demand d-flex align-items-center px-2 py-1">
                <span class="emoji me-2" *ngIf="demandTask.demand?.emoji_id">
                  {{ getEmoji(demandTask.demand?.emoji_id) }}
                </span>
                <h6 class="mb-0 text-sm py-2 pointer demand-name" 
                    (click)="showDemand(demandTask.demand.id, content)">
                  <p class="title text-xs">
                    <span class="project-code me-2" *ngIf="demandTask.demand?.project?.code && demandTask.demand?.item_id">
                      {{ demandTask.demand?.project?.code }}.{{ demandTask.demand?.item_id }}
                    </span>
                    <span class="demand-name">{{ demandTask.demand?.name }}</span>
                    <span class="project-name d-block" *ngIf="demandTask.demand?.project?.name">
                      {{ demandTask.demand?.project?.name }}
                    </span>
                  </p>
                </h6>
              </div>
            </td>
            <td class="align-middle text-center">{{ demandTask.task?.name }}</td>
            <td class="align-middle text-center">
              <span [class.text-secondary]="!demandTask.expired" 
                    [class.text-danger]="demandTask.expired">
                {{ demandTask.due_date | date: "dd/MM/yyyy" }}
              </span>
            </td>
            <td class="align-middle text-center text-sm">
              <div class="badge p-0 bg-white">
                <span class="badge-status" [style.background]="demandTask.status?.color">
                  {{ demandTask.status.name }}
                </span>
              </div>
            </td>
            <td class="align-middle text-center position-relative">
              <button class="btn btn-options mb-0" (click)="toggleOptions(i)">
                <i class="fa fa-ellipsis-v text-xs" aria-hidden="true"></i>
              </button>

              <ul class="options-box" [class.d-none]="selectedRowIndex !== i">
                <li (click)="showDemand(demandTask.demand.id, content)">
                  <span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="15" viewBox="0 0 14 15" fill="none">
                      <path d="M8.51242 2.91162L0.928257 10.5044C0.890156 10.5428 0.86242 10.591 0.849533 10.6429L0.00877784 14.0207C-0.0164364 14.1225 0.0135405 14.2305 0.0875023 14.3048C0.143534 14.3609 0.220017 14.392 0.298181 14.392C0.321995 14.392 0.346649 14.3889 0.370462 14.3831L3.74441 13.5414C3.79708 13.5282 3.8447 13.5007 3.88281 13.4626L11.4678 5.87041L8.51242 2.91162Z" fill="white"/>
                      <path d="M13.3468 1.87646L12.5027 1.03145C11.9385 0.466613 10.9551 0.467174 10.3914 1.03145L9.35738 2.06661L12.3128 5.02512L13.3468 3.98996C13.6287 3.70783 13.7839 3.33258 13.7839 2.93321C13.7839 2.53384 13.6287 2.1586 13.3468 1.87646Z" fill="white"/>
                    </svg>
                  </span> Editar</li>
                <li (click)="showDemand(demandTask.demand.id, content)">
                <span>
                  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="19" viewBox="0 0 18 19" fill="none">
                    <path d="M9 3.38281C4.90909 3.38281 1.41545 5.91941 0 9.5C1.41545 13.0806 4.90909 15.6172 9 15.6172C13.095 15.6172 16.5845 13.0806 18 9.5C16.5845 5.91941 13.095 3.38281 9 3.38281ZM9 13.5781C6.74182 13.5781 4.90909 11.7511 4.90909 9.5C4.90909 7.24887 6.74182 5.42188 9 5.42188C11.2582 5.42188 13.0909 7.24887 13.0909 9.5C13.0909 11.7511 11.2582 13.5781 9 13.5781ZM9 7.05313C7.64591 7.05313 6.54545 8.15014 6.54545 9.5C6.54545 10.8499 7.64591 11.9469 9 11.9469C10.3541 11.9469 11.4545 10.8499 11.4545 9.5C11.4545 8.15014 10.3541 7.05313 9 7.05313Z" fill="white"/>
                  </svg>
                </span> Visualizar</li>
                <li (click)="deleteDemand(demandTask.demand.id)">
                <span>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
                    <path d="M15.1923 2.61538H11.9234V1.96154C11.9228 1.44149 11.7159 0.942903 11.3482 0.575171C10.9805 0.207438 10.4819 0.000587961 9.96182 0H6.03874C5.51869 0.000587961 5.02011 0.207438 4.65238 0.575171C4.28464 0.942903 4.07779 1.44149 4.07721 1.96154V2.61538H0.807655C0.634244 2.61538 0.467936 2.68427 0.345316 2.80689C0.222696 2.92951 0.153809 3.09582 0.153809 3.26923C0.153809 3.44264 0.222696 3.60895 0.345316 3.73157C0.467936 3.85419 0.634244 3.92308 0.807655 3.92308H1.4615V15.6923C1.4619 16.039 1.5998 16.3714 1.84495 16.6165C2.09011 16.8617 2.42249 16.9996 2.76919 17H13.2307C13.5774 16.9996 13.9098 16.8617 14.155 16.6165C14.4001 16.3714 14.538 16.039 14.5384 15.6923V3.92308H15.1923C15.3657 3.92308 15.532 3.85419 15.6546 3.73157C15.7772 3.60895 15.8461 3.44264 15.8461 3.26923C15.8461 3.09582 15.7772 2.92951 15.6546 2.80689C15.532 2.68427 15.3657 2.61538 15.1923 2.61538ZM6.69259 12.4231C6.69259 12.5965 6.6237 12.7628 6.50108 12.8854C6.37846 13.008 6.21215 13.0769 6.03874 13.0769C5.86533 13.0769 5.69903 13.008 5.57641 12.8854C5.45379 12.7628 5.3849 12.5965 5.3849 12.4231V7.19231C5.3849 7.0189 5.45379 6.85259 5.57641 6.72997C5.69903 6.60735 5.86533 6.53846 6.03874 6.53846C6.21215 6.53846 6.37846 6.60735 6.50108 6.72997C6.6237 6.85259 6.69259 7.0189 6.69259 7.19231V12.4231ZM10.6157 12.4231C10.6157 12.5965 10.5468 12.7628 10.4242 12.8854C10.3015 13.008 10.1352 13.0769 9.96182 13.0769C9.78841 13.0769 9.6221 13.008 9.49948 12.8854C9.37686 12.7628 9.30797 12.5965 9.30797 12.4231V7.19231C9.30797 7.0189 9.37686 6.85259 9.49948 6.72997C9.6221 6.60735 9.78841 6.53846 9.96182 6.53846C10.1352 6.53846 10.3015 6.60735 10.4242 6.72997C10.5468 6.85259 10.6157 7.0189 10.6157 7.19231V12.4231ZM10.6157 2.61538H5.3849V1.96154C5.3851 1.78819 5.45405 1.622 5.57662 1.49942C5.6992 1.37684 5.86539 1.30789 6.03874 1.30769H9.96182C10.1352 1.30789 10.3014 1.37684 10.4239 1.49942C10.5465 1.622 10.6155 1.78819 10.6157 1.96154V2.61538Z" fill="white"/>
                  </svg>
                </span> Excluir</li>
              </ul>
            </td>
          </tr>
        </tbody>
      </table>

      <!-- Tabela vazia -->
      <div class="text-center d-block py-5" 
           *ngIf="((demands?.data?.length == 0 && context === 'demand') || (demandsTasks?.data?.length == 0 && context === 'task')) && !skeleton">
        <small>{{ emptyTableText }}</small>
        <div class="d-flex justify-content-center">
          <ng-lottie [options]="options" height="300px" (animationCreated)="animationCreated($event)"></ng-lottie>
        </div>
      </div>
    </div>

    <!-- Paginação -->
    <nav aria-label="Page navigation example" *ngIf="demands.last_page > 1 && !limit">
      <ul class="pagination justify-content-end mt-4">
        <li class="page-item" *ngFor="let p of pagination" [class.active]="demands.current_page == p">
          <a class="page-link" (click)="goPage(p)" href="javascript:;">{{ p }}</a>
        </li>
      </ul>
    </nav>

    <nav aria-label="Page navigation example" *ngIf="demandsTasks.last_page > 1 && !limit">
      <ul class="pagination justify-content-end mt-4">
        <li class="page-item" *ngFor="let p of pagination" [class.active]="demands.current_page == p">
          <a class="page-link" (click)="goPage(p)" href="javascript:;">{{ p }}</a>
        </li>
      </ul>
    </nav>
  </div>
</div>

<app-sidebar-filter 
#sideBarFilter
[fixedFilters]="getFixedFilters()"
[saveOptionsOnFilter]="saveOptionsOnFilter">
</app-sidebar-filter>

<!-- Modais -->
<ng-template #content let-modal>
  <app-demands-form #demand [demand_id]="demand_id" 
                    (demandHeader)="demandHeader($event)" 
                    (cancelModal)="modal.dismiss('Cross click')" 
                    (updateCalendar)="getDemands()" 
                    [modal]="true">
  </app-demands-form>
</ng-template>

<ng-template #create_demand let-modal>
  <app-requester-demands-form (demandCreated)="getRecords()" (close)="modal.dismiss('Cross click')"></app-requester-demands-form>
</ng-template>