import { Component, OnInit } from '@angular/core';
import { FieldService } from 'src/app/shared/services/data/field.service';
import { TaskService } from 'src/app/shared/services/demands/task.service';
import { UserService } from 'src/app/shared/services/user-management/user.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-tasks-management',
  templateUrl: './tasks-management.component.html',
  styleUrl: './tasks-management.component.scss'
})
export class TasksManagementComponent {
  tasks = [];
  skeleton = true;
  query: any;
  order = 'asc';
  orderBy = 'order'
  page = 1;
  currentUser:any = null;

  constructor(
    private taskService: TaskService,
    private userService: UserService
  ) { }

  ngOnInit(): void {
    this.currentUser = this.userService.getLocalUser();
    this.get();
  }

  get() {
    this.taskService.getAll({
      limit:  null, 
      paginate: 1000,
      query:  this.query,
      orderBy: this.orderBy,
      order:  this.order,
      page:   this.page,
      area_id: this.currentUser.area_id
    }).subscribe({
      next: (result:any) => {
        this.skeleton   = false
        this.tasks = result;
        console.log(this.tasks)
      },
      error: (err) => console.log(err)
    })
  }

  delete(item: any) {
    Swal.fire({
      icon: 'warning',
      text: `Deseja remover o campo ${item.name}?`,
      showCancelButton: true,
      confirmButtonText: 'Excluir',
      cancelButtonText: 'Cancelar',
      confirmButtonColor: '#f5365c',
    }).then(result => {
      if (result.value) {
        if (item.id) {
          this.taskService.deleteById(item.id).subscribe({
            next: () => this.get(),
            error: (err) => console.log(err)
          })
        } 
      }
    })
  }

  setOrder(order: any) {
    this.order = order
    this.get()
  }

  setPage(p: number) {
    this.page = p
    this.get()
  }

  setSearch(query: string) {
    this.query = query
    this.get()
  }
 
}