<div class="g-sidenav-show min-height-100-vh main-bg">
    <!-- <div *ngIf="!modal" class="min-height-300 bg-gradient-red position-fixed w-100"></div> -->
    <div id="topbar" class="bg-white py-2 pe-3 d-flex justify-content-between" [class.expand]="isOpen">
        <div class="input-group flex-nowrap d-none" id="search">
            <span class="input-group-text" id="addon-wrapping"><i class="fas fa-search fa-sm"></i></span>
            <input type="text" class="form-control" placeholder="Pesquisar..." aria-label="pesquisar" aria-describedby="addon-wrapping">
        </div>
        <div class="ms-auto">
            <a class="border-0 shadow-none bg-transparent me-3 p-0" ngbTooltip="Atividades" [routerLink]="'events'" *ngIf="['analyst'].includes(currentUser.group.name)">
                <img src="assets/argon-dashboard-master/assets/img/claro/icons/agenda.svg" alt="">
            </a>
            <!-- <button type="button" class="border-0 shadow-none bg-transparent me-3 p-0">
                <img src="assets/argon-dashboard-master/assets/img/claro/icons/notifications.svg" alt="">
            </button> -->
            <a href="assets/argon-dashboard-master/assets/img/claro/docs/AURORA.pdf" target="_blank" class="me-2">
                <img src="assets/argon-dashboard-master/assets/img/claro/icons/question-mark.svg" alt="" width="30" height="30">
            </a>
            <button type="button" class="border-0 shadow-none bg-transparent" [routerLink]="'user/profile'">
                <img [src]="currentUser?.avatar ?? 'assets/argon-dashboard-master/assets/img/default-user.png'" class="avatar avatar-lg avatar-sm rounded-circle" [alt]="currentUser.name">
                <small class="text-secondary ms-2">{{currentUser.name}}</small>
            </button>
        </div>
    </div>
    <aside *ngIf="!modal" [class.open]="isOpen" id="sidenav-main">
        <app-side-menu [menu]="menu" (isOpen)="isOpen = $event"></app-side-menu>
    </aside>
    <main class="main-content position-relative border-radius-lg" [class.expand]="isOpen">
        <app-nav *ngIf="!modal" [title]="title"></app-nav>
        <div class="container-fluid pb-4">
            <ng-content></ng-content>
        </div>
    </main>
</div>