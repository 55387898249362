import { Component, OnInit, Input, Output, EventEmitter, HostListener, ViewChild, ElementRef } from '@angular/core';
import { DemandsService } from '../../services/demands/demands.service';
import { DemandTasksService } from '../../services/demands/demand-tasks.service';
import { AreaService } from '../../services/user-management/area.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute } from '@angular/router';
import { UserService } from '../../services/user-management/user.service';
import { StatusService } from '../../services/demands/status.service';
import { ProjectsService } from '../../services/demands/projects.service';
import { FiltersService } from '../../services/data/filters.service';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-sidebar-filter',
  templateUrl: './sidebar-filter.component.html',
  styleUrls: ['./sidebar-filter.component.scss']
})
export class SidebarFilterComponent implements OnInit {
  currentUser: any = null;
  demandsCount = 0;

  @Input() showSideBarFilter:boolean = false;
  @Input() showActiveUsersViewAccordion:boolean = false;
  @Input() showDemandStatusAccordion:boolean = true;
  @Input() showDemandTaskStatusAccordion:boolean = true;
  @Input() showRequesterAccordion:boolean = true;
  @Input() showInvolvedUsersAccordion:boolean = true;

  @Input() activeTab:boolean = true;

  @Input() saveOptionsOnFilter:boolean = true;

  // Novo Input para filtros fixos
  @Input() fixedFilters: Partial<typeof this.filters> = {};

  @ViewChild('sidebarFilter') sidebarFilter!: ElementRef;

  context: string = 'demand';
  filters = {
    range: 'all',
    context: 'demand',
    created_at: '',
    deadline_start: '',
    deadline_end: '',
    demand_status_id: [] as any[],
    project_id: [] as number[],
    demand_task_status_id: [] as any[],
    involved_user_id: [] as number[],
    requester_id: [] as number[],
    area_id: [] as number[],
    order: 'desc',
    orderBy: 'deadline',
    query: '',
    active_users_ids: [] as any[]
  };

  @Output() filtersLoaded = new EventEmitter();

  demandTaskStatusItems: any = [];
  demandStatusItems: any = [];
  involvedUsersItems: any = [];
  requesterUsersItems: any = [];
  projectItems: any = [];
  areasItems: any = [];

  contextItems = [
    { name: 'Demandas', value: 'demand' },
  ];

  rangeItems = [
    { name: 'Todas', value: 'all' },
    { name: 'Atrasadas', value: 'late' },
    { name: 'No prazo', value: 'on_time' },
    { name: 'Personalizado', value: 'custom' },
  ];

  involvedUsers: any[] = [];
  requesters: any[] = [];
  active_users:any;

  constructor(
    private demandsService: DemandsService,
    private demandTaskService: DemandTasksService,
    private areaService: AreaService,
    private filterService: FiltersService,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private userService: UserService,
    private statusService: StatusService,
    private projectService: ProjectsService
  ) {}

  ngOnInit() {
    this.currentUser = this.userService.getLocalUser();
    if (['analyst'].includes(this.currentUser.group.name)) {
      this.contextItems.push({ name: 'Demandas e tarefas', value: 'task' });
    }

    forkJoin([
      this.statusService.getAll({ context: 'demand' }),
      this.statusService.getAll({ context: 'task' }),
      this.areaService.getAll(),
      this.projectService.getAll(),
      this.userService.getAll({ group: ['analyst', 'screening', 'leadership'] }),
      this.userService.getAll({ group: ['requester'] }),
      this.userService.getAll(),
    ]).subscribe({
      next: ([demandStatusItems, demandTaskStatusItems, areasItems, projectItems, involvedUsersItems, requesterUsersItems, active_users]) => {
        this.demandStatusItems = demandStatusItems;
        this.demandTaskStatusItems = demandTaskStatusItems;
        this.areasItems = areasItems;
        this.projectItems = projectItems;
        this.involvedUsersItems = involvedUsersItems;
        this.requesterUsersItems = requesterUsersItems;
        this.active_users = active_users

       // Aplica os filtros fixos iniciais
       this.applyFixedFilters();

        this.loadFilters();
      },
      error: (err) => console.error('Erro ao carregar dados iniciais:', err)
    });
  }

  // Método para aplicar filtros fixos
  private applyFixedFilters() {
    if (this.fixedFilters) {
      Object.keys(this.fixedFilters).forEach(key => {
        if (key in this.filters) {
          this.filters[key as keyof typeof this.filters] = this.fixedFilters[key as keyof typeof this.filters] as any;
        }
      });
      console.log(this.filters);
    }
  }

  getUsers() { 
    this.userService.getAll().subscribe({
      next: (result) => this.active_users = result,
      error: (err) => console.error(err)
    })
  }

  applyFilters() {
    this.filters.involved_user_id = this.involvedUsers.map(u => u.id);
    this.filters.requester_id = this.requesters.map(r => r.id);
    this.filters.context = this.context;

    // Reaplica os filtros fixos após qualquer alteração
    this.applyFixedFilters();

    const persistentFilters = { ...this.filters };
    delete persistentFilters.query;

    this.filtersLoaded.emit(this.filters);

    if (this.saveOptionsOnFilter) {
      this.filterService.save(this.context, persistentFilters).subscribe(
        response => console.log('Filtro salvo:', response),
        error => console.error('Erro ao salvar filtro:', error)
      );
    }
  }

  resetFilters() {
    this.filters = {
      range: 'all',
      context: this.filters.context || 'demand',
      created_at: '',
      deadline_start: '',
      deadline_end: '',
      demand_status_id: [],
      project_id: [],
      demand_task_status_id: [],
      involved_user_id: [],
      requester_id: [],
      area_id: [],
      order: 'desc',
      orderBy: 'deadline',
      query: '',
      active_users_ids: []
    };
    this.involvedUsers = [];
    this.requesters = [];

    this.applyFilters();
  }

  clearSpecificFilter(filterKey: keyof typeof this.filters) {
    // Não limpa filtros que estão em fixedFilters
    if (this.fixedFilters && filterKey in this.fixedFilters) {
      return;
    }

    if (Array.isArray(this.filters[filterKey])) {
      (this.filters[filterKey] as any[]) = [];
    } else {
      this.filters[filterKey] = '' as any;
      if (filterKey === 'range') this.filters[filterKey] = 'all';
    }

    if (filterKey === 'involved_user_id') {
      this.involvedUsers = [];
    } else if (filterKey === 'requester_id') {
      this.requesters = [];
    }

    this.applyFilters();
  }

  loadFilters() {
    this.filterService.getAll({
      context: this.context,
      user_id: this.currentUser.id
    }).subscribe({
      next: (result: any) => {
          this.filters.range = result?.filters?.range || 'all';
          this.filters.created_at = result?.filters?.created_at || '';
          this.filters.deadline_start = result?.filters?.deadline_start || '';
          this.filters.deadline_end = result?.filters?.deadline_end || '';
          this.filters.demand_status_id = result?.filters?.demand_status_id || [];
          this.filters.demand_task_status_id = result?.filters?.demand_task_status_id || [];
          this.filters.project_id = result?.filters?.project_id || [];
          this.filters.involved_user_id = result?.filters?.involved_user_id || [];
          this.filters.requester_id = result?.filters?.requester_id || [];
          this.filters.area_id = result?.filters?.area_id || [this.currentUser.area_id];
          this.filters.order = result?.filters?.order || 'desc';
          this.filters.orderBy = result?.filters?.orderBy || 'created_at';
          this.filters.active_users_ids = result?.filters?.active_users_ids || [];

          this.involvedUsers = this.involvedUsersItems.filter(u => this.filters.involved_user_id.includes(u.id));
          this.requesters = this.requesterUsersItems.filter(r => this.filters.requester_id.includes(r.id));

          // Atualiza active_users com base em active_users_ids
          if (this.active_users && this.filters.active_users_ids.length > 0) {
            this.active_users.forEach(user => {
              user.selected = this.filters.active_users_ids.includes(user.id);
            });
          }

          this.clearDeadlinesIfNotCustom();

          // Aplica os filtros fixos após carregar do backend
          this.applyFixedFilters();
          
          console.log(this.filters);

          this.filtersLoaded.emit(this.filters);
      },
      error: (err) => console.error('Erro ao carregar filtros:', err)
    });
  }

  onInvolvedUsersChange(selectedUsers: any[]) {
    if (!this.fixedFilters?.involved_user_id) {
      this.involvedUsers = selectedUsers;
      this.filters.involved_user_id = selectedUsers.map(u => u.id);
    }
  }

  onRequestersChange(selectedUsers: any[]) {
    if (!this.fixedFilters?.requester_id) {
      this.requesters = selectedUsers;
      this.filters.requester_id = selectedUsers.map(r => r.id);
    }
  }

  toggleSideBarFilter() {
    this.showSideBarFilter = !this.showSideBarFilter;
  }

  //order
  toggleOrder() { 
    if (this.filters.order === 'asc') this.filters.order = 'desc'
    else this.filters.order = 'asc'
  }

  onRangeChange(newRange: string) {
    this.filters.range = newRange;
    this.clearDeadlinesIfNotCustom();
  }

  private clearDeadlinesIfNotCustom() {
    if (this.filters.range !== 'custom') {
      this.filters.deadline_start = '';
      this.filters.deadline_end = '';
    }
  }

  updateActiveUsers(): void {
    this.filters.active_users_ids = this.active_users.filter(u => u.selected).map(u => u.id) || [];
  }

  // @HostListener('document:click', ['$event'])
  // onDocumentClick(event: MouseEvent) {
  //   if (this.showSideBarFilter && 
  //       this.sidebarFilter && 
  //       !this.sidebarFilter.nativeElement.contains(event.target)) {
  //     this.showSideBarFilter = false;
  //   }
  // }
}