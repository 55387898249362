<aside id="sidebar-filter" #sidebarFilter [class.show]="showSideBarFilter">
    <span><i class="bi bi-chevron-right pointer" (click)="toggleSideBarFilter()"></i> Preferências</span>
    <div class="filter-actions d-flex align-items-center pt-3 pb-4">
      <button class="btn btn-primary" (click)="applyFilters()"><i class="fa-solid fa-filter me-2"></i> Filtrar</button>
      <button class="btn btn-secondary ms-2" (click)="resetFilters()"><i class="fa-solid fa-filter-circle-xmark me-2"></i> Limpar todas</button>
    </div>
  <div class="input-group mb-3">
    <span class="input-group-text"><i class="bi bi-search"></i></span>
    <input type="text" class="form-control" placeholder="Busca" aria-label="Busca" [(ngModel)]="filters.query" style="border-color: #d2d6da!important">
  </div>
  <div class="accordion accordion-flush" id="accordion-filters">
    <div class="accordion-item">
      <h2 class="accordion-header">
        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse-tipo" aria-expanded="false" aria-controls="flush-collapse-tipo">
          <div class="d-flex justify-content-between w-100">Tipo</div>
        </button>
      </h2>
      <div id="flush-collapse-tipo" class="accordion-collapse collapse" data-bs-parent="#accordion-filters">
        <div class="accordion-body">
          <div class="form-group d-flex justify-content-between align-items-center mb-0">
            <ng-select 
              [items]="contextItems"
              [multiple]="false"
              [searchable]="false"
              bindLabel="name"
              bindValue="value"
              [(ngModel)]="context"
              [clearable]="false"
              class="w-100">
            </ng-select>
          </div>
        </div>
      </div>
    </div>
    <div class="accordion-item">
      <h2 class="accordion-header">
        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse-prazo" aria-expanded="false" aria-controls="flush-collapse-prazo">
          <div class="d-flex justify-content-between w-100">Prazo <span class="filled-filter ms-auto" [ngbTooltip]="'Limpar filtro'" *ngIf="filters.range !== 'all'" (click)="clearSpecificFilter('range')"><i class="fa-solid fa-filter-circle-xmark"></i></span></div>
        </button>
      </h2>
      <div id="flush-collapse-prazo" class="accordion-collapse collapse" data-bs-parent="#accordion-filters">
        <div class="accordion-body">
          <div class="form-group d-flex justify-content-between align-items-center mb-0">
            <ng-select 
              [items]="rangeItems"
              [multiple]="false"
              [searchable]="false"
              bindLabel="name"
              bindValue="value"
              [(ngModel)]="filters.range"
              class="w-100">
            </ng-select>
          </div>
          <div *ngIf="filters.range === 'custom'" class="form-group d-flex justify-content-between align-items-center mb-0 mt-2">
            <div class="input-group mb-3 me-2">
              <input type="date" class="form-control" [(ngModel)]="filters.deadline_start">
            </div>
            <div class="input-group mb-3">
              <input type="date" class="form-control" [(ngModel)]="filters.deadline_end">
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="accordion-item" *ngIf="context === 'demand' && !fixedFilters.area_id">
      <h2 class="accordion-header">
        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse-areas" aria-expanded="false" aria-controls="flush-collapse-areas">
          <div class="d-flex justify-content-between w-100">Áreas <span class="filled-filter ms-auto" [ngbTooltip]="'Limpar filtro'" *ngIf="filters.area_id.length > 0" (click)="clearSpecificFilter('area_id')"><i class="fa-solid fa-filter-circle-xmark"></i></span></div>
        </button>
      </h2>
      <div id="flush-collapse-areas" class="accordion-collapse collapse" data-bs-parent="#accordion-filters">
        <div class="accordion-body"> 
          <ng-select 
            [items]="areasItems"
            [(ngModel)]="filters.area_id"
            bindValue="id"
            bindLabel="name"
            [multiple]="true"
            [closeOnSelect]="false"
            class="w-100">
          </ng-select>
        </div>
      </div>
    </div>
    <div class="accordion-item" *ngIf="!fixedFilters?.demand_status_id">
      <h2 class="accordion-header">
        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse-demand-status" aria-expanded="false" aria-controls="flush-collapse-demand-status">
          <div class="d-flex justify-content-between w-100">Status da demanda <span class="filled-filter ms-auto" [ngbTooltip]="'Limpar filtro'" *ngIf="filters.demand_status_id.length > 0" (click)="clearSpecificFilter('demand_status_id')"><i class="fa-solid fa-filter-circle-xmark"></i></span></div>
        </button>
      </h2>
      <div id="flush-collapse-demand-status" class="accordion-collapse collapse" data-bs-parent="#accordion-filters">
        <div class="accordion-body"> 
          <ng-select 
            [items]="demandStatusItems"
            [(ngModel)]="filters.demand_status_id"
            bindValue="id"
            bindLabel="name"
            [multiple]="true"
            [closeOnSelect]="false"
            class="w-100">
          </ng-select>
        </div>
      </div>
    </div>
    <div class="accordion-item" *ngIf="!fixedFilters?.demand_task_status_id">
      <h2 class="accordion-header">
        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse-task-status" aria-expanded="false" aria-controls="flush-collapse-task-status">
          <div class="d-flex justify-content-between w-100">Status da tarefa <span class="filled-filter ms-auto" [ngbTooltip]="'Limpar filtro'" *ngIf="filters.demand_task_status_id.length > 0" (click)="clearSpecificFilter('demand_task_status_id')"><i class="fa-solid fa-filter-circle-xmark"></i></span></div>
        </button>
      </h2>
      <div id="flush-collapse-task-status" class="accordion-collapse collapse" data-bs-parent="#accordion-filters">
        <div class="accordion-body">
          <ng-select 
            [items]="demandTaskStatusItems"
            [(ngModel)]="filters.demand_task_status_id"
            bindValue="id"
            bindLabel="name"
            [multiple]="true"
            [closeOnSelect]="false"
            class="w-100">
          </ng-select>
        </div>
      </div>
    </div>
    <div class="accordion-item" *ngIf="!fixedFilters?.project_id">
      <h2 class="accordion-header">
        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse-project" aria-expanded="false" aria-controls="flush-collapse-project">
          <div class="d-flex justify-content-between w-100">Projeto <span class="filled-filter ms-auto" [ngbTooltip]="'Limpar filtro'" *ngIf="filters.project_id.length > 0" (click)="clearSpecificFilter('project_id')"><i class="fa-solid fa-filter-circle-xmark"></i></span></div>
        </button>
      </h2>
      <div id="flush-collapse-project" class="accordion-collapse collapse" data-bs-parent="#accordion-filters">
        <div class="accordion-body">
          <ng-select 
            [items]="projectItems"
            bindValue="id"
            bindLabel="name"
            [multiple]="true"
            [closeOnSelect]="false"
            [(ngModel)]="filters.project_id"
            class="w-100">
          </ng-select>
        </div>
      </div>
    </div>
    <div class="accordion-item" *ngIf="!fixedFilters?.involved_user_id">
      <h2 class="accordion-header">
        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse-involved-users" aria-expanded="false" aria-controls="flush-collapse-involved-users">
          <div class="d-flex justify-content-between w-100">Envolvido <span class="filled-filter ms-auto" [ngbTooltip]="'Limpar filtro'" *ngIf="involvedUsers.length > 0" (click)="clearSpecificFilter('involved_user_id')"><i class="fa-solid fa-filter-circle-xmark"></i></span></div>
        </button>
      </h2>
      <div id="flush-collapse-involved-users" class="accordion-collapse collapse" data-bs-parent="#accordion-filters">
        <div class="accordion-body d-flex align-items-center">
          <app-user-selector 
            [users]="involvedUsersItems" 
            [selectedUsers]="involvedUsers" 
            [label]="'Selecione os envolvidos'" 
            (selectedUsersChange)="onInvolvedUsersChange($event)">
          </app-user-selector>
        </div>
      </div>
    </div>
    <div class="accordion-item" *ngIf="!fixedFilters?.requester_id">
      <h2 class="accordion-header">
        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse-requesters" aria-expanded="false" aria-controls="flush-collapse-requesters">
          <div class="d-flex justify-content-between w-100">Demandante <span class="filled-filter ms-auto" [ngbTooltip]="'Limpar filtro'" *ngIf="requesters.length > 0" (click)="clearSpecificFilter('requester_id')"><i class="fa-solid fa-filter-circle-xmark"></i></span></div>
        </button>
      </h2>
      <div id="flush-collapse-requesters" class="accordion-collapse collapse" data-bs-parent="#accordion-filters">
        <div class="accordion-body d-flex align-items-center">
          <app-user-selector 
            [users]="requesterUsersItems" 
            [selectedUsers]="requesters" 
            [label]="'Selecione os demandantes'" 
            (selectedUsersChange)="onRequestersChange($event)">
          </app-user-selector>
        </div>
      </div>
    </div>
  </div>



<!-- Configuração de exibição para usuários -->
  <div class="accordion accordion-flush mt-5" id="accordion-view-config">
    <div class="accordion-item" *ngIf="showActiveUsersViewAccordion">
      <h2 class="accordion-header">
        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse-involved-users-active-columns" aria-expanded="false" aria-controls="flush-collapse-involved-users-active-columns">
          <div class="d-flex justify-content-between w-100">Usuários ativos (colunas)</div>
        </button>
      </h2>
      <div id="flush-collapse-involved-users-active-columns" class="accordion-collapse collapse" data-bs-parent="#accordion-view-config">
        <div class="accordion-body">
          <div class="form-group">
            <div class="d-flex align-items-center mb-2" *ngFor="let u of active_users">
              <img [src]="u.avatar || 'assets/argon-dashboard-master/assets/img/default-user.png'" alt="{{u.name}}" class="avatar avatar-sm rounded-circle me-2" [ngbTooltip]="u.name">
              <div class="form-check form-switch flex-grow-1 justify-content-between ps-0">
                <label class="form-check-label" [for]="'user-' + u.id">{{u.name}}</label>
                <input class="form-check-input ms-0" type="checkbox" [id]="'user-' + u.id" [(ngModel)]="u.selected" (change)="updateActiveUsers()">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</aside>