<app-default-table
    pageTitle="TAREFAS"
    tableTitle="Tarefas"
    urlNewItem="/leadership/tasks/new"
    textNewItem="Nova tarefa"
    editRouter="/leadership/tasks/edit"
    [record]="tasks"
    [skeleton]="skeleton"
    (eventSearch)="setSearch($event)"
    (eventDelete)="delete($event)"
    (eventOrder)="setOrder($event)"
    (eventPage)="setPage($event)"
    model="tasks"
    [updateOrder]="true"
    (eventOrderByName)="orderBy = 'name'; get()">
</app-default-table>