import { Component, Input, Output, EventEmitter, ViewChild, ElementRef, HostListener } from '@angular/core';

@Component({
  selector: 'app-user-selector',
  templateUrl: './user-selector.component.html',
  styleUrl: './user-selector.component.scss'
})
export class UserSelectorComponent {
  @Input() users: any[] = []; // Lista de usuários disponíveis
  @Input() selectedUsers: any[] = []; // Lista de usuários selecionados
  @Input() label: string = 'Usuários'; // Rótulo para personalizar (ex.: "Envolvidos", "Demandantes")
  @Output() selectedUsersChange = new EventEmitter<any[]>(); // Emite a lista atualizada de selecionados

  showUserBox = false;
  searchQuery = '';

  @ViewChild('userListEl') userListEl!: ElementRef;
  @ViewChild('userListBtnEl') userListBtnEl!: ElementRef;

  toggleUserBox() {
    this.showUserBox = !this.showUserBox;
  }

  isUserSelected(user: any): boolean {
    return this.selectedUsers.some(selected => selected.id === user.id);
  }

  toggleUser(user: any, event: MouseEvent) {
    event.stopPropagation();
    if (this.isUserSelected(user)) {
      this.selectedUsers = this.selectedUsers.filter(u => u.id !== user.id);
    } else {
      this.selectedUsers = [...this.selectedUsers, user];
    }
    this.selectedUsersChange.emit(this.selectedUsers); // Emite a lista atualizada
  }

  removeUser(user: any) {
    this.selectedUsers = this.selectedUsers.filter(u => u.id !== user.id);
    this.selectedUsersChange.emit(this.selectedUsers); // Emite a lista atualizada
  }

  filterUsers(user: any): boolean {
    if (!this.searchQuery) return true;
    return user.name.toLowerCase().includes(this.searchQuery.toLowerCase());
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    if (this.showUserBox &&
        this.userListEl &&
        !this.userListEl.nativeElement.contains(event.target) &&
        this.userListBtnEl &&
        !this.userListBtnEl.nativeElement.contains(event.target)) {
      this.showUserBox = false;
    }
  }
}