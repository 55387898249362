import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from 'src/app/core/shared/services/base.service';

@Injectable({
  providedIn: 'root'
})
export class DataService extends BaseService {

  uploadFile(file: File): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    return this.http.post<any>(this.apiUrl, formData);
  }

  aiInsights(data: any, insight: any) {
    //let api = 'https://botman.plugadosead.fiqueligadonews.com.br/api/teste'
    let api = 'http://127.0.0.1:8001/api/teste'
    return this.http.post<any>(api, {
      messages: '',
      historic: [ 
        {
          'role': 'system', 'content': 'Você é um assistente muito prestativo da plataforma Smarthub, sua função é ajudar na análise de dados. Organize os insights de forma clara e estruturada, fornecendo recomendações práticas e acionáveis para melhorar o desempenho e a eficiência dos call centers."'
        },
        { 
          'role': 'user', 'content': JSON.stringify({insight: insight, data: data.distribuicao_nps, legendas: 'TS = Tempo de Silencio, NPS = pontuação do operador, RECH = Rechamadas'})
        } 
      ]
    });
  }

  getAll(filter: any = null) {
    return this.post(`analyse/filter`, filter)
  }

  getById(id: number) {
    return this.get(`analyse/${id}`)
  }

  save(data: any) {
    return this.post(`analyse`, data)
  }

  saveInsight(id: number, data: any) {
    return this.post(`analyse/${id}/insight`, data)
  }

  deleteById(id: number) {
    return this.delete(`analyse/${id}`)
  }

  updateOrder(model:string, data:any) { 
    return this.post(`${model}/update-order`, data)
  }

}
