<div class="card kanban-box-container">
    <div class="demand-tab-selectors">
        <button (click)="getKanban('involved-users')" 
                [class.selected]="activeTab === 'involved-users'"
                class="selector-btn"
                *ngIf="!['requester'].includes(currentUser.group.name)">
          <span>
            <i class="fa-solid fa-table-list fa-rotate-90"></i>
        </span> Envolvidos
        </button>
        <button (click)="getKanban('demand-status')" 
                [class.selected]="activeTab === 'demand-status'"
                class="selector-btn">
          <span>
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="20" viewBox="0 0 18 20" fill="none">
              <path d="M11.7692 20H6.23077C4.87662 20 3.89908 20 3.09877 19.8912C2.15723 19.7628 1.512 19.4809 1.01354 18.9785C0.512308 18.4733 0.232615 17.8258 0.108 16.8769C0 16.0703 0 15.0851 0 13.7203V8.13843C0 6.77365 0 5.78845 0.108 4.98186C0.235385 4.03293 0.515077 3.38264 1.01354 2.88027C1.94123 1.9453 3.25385 1.87273 5.30308 1.85878L5.31138 3.25426C3.38954 3.26542 2.50338 3.35194 1.99385 3.86548C1.38462 4.47949 1.38462 5.70193 1.38462 8.13564V13.7176C1.38462 16.1541 1.38462 17.3765 1.99385 17.9877C2.60308 18.6017 3.816 18.6017 6.23077 18.6017H11.7692C14.1868 18.6017 15.3997 18.6017 16.0062 17.9877C16.6154 17.3737 16.6154 16.1513 16.6154 13.7176V8.13564C16.6154 5.69913 16.6154 4.4767 16.0062 3.86548C15.4966 3.35194 14.6105 3.26542 12.6886 3.25426L12.6969 1.85878C14.7489 1.86994 16.0588 1.9453 16.9865 2.88027C17.4877 3.38543 17.7674 4.03293 17.892 4.98186C18 5.78845 18 6.77365 18 8.13843V13.7203C18 15.0851 18 16.0703 17.892 16.8769C17.7646 17.8258 17.4849 18.4761 16.9865 18.9785C16.488 19.4809 15.8428 19.7656 14.9012 19.8912C14.1009 20 13.1234 20 11.7692 20Z"/>
              <path d="M13.6163 12.5593H7.61538C7.23323 12.5593 6.92308 12.2467 6.92308 11.8616C6.92308 11.4764 7.23323 11.1638 7.61538 11.1638H13.6163C13.9985 11.1638 14.3086 11.4764 14.3086 11.8616C14.3086 12.2467 13.9985 12.5593 13.6163 12.5593Z"/>
              <path d="M4.84615 12.5593H4.38369C4.00154 12.5593 3.69138 12.2467 3.69138 11.8616C3.69138 11.4764 4.00154 11.1638 4.38369 11.1638H4.84615C5.22831 11.1638 5.53846 11.4764 5.53846 11.8616C5.53846 12.2467 5.22831 12.5593 4.84615 12.5593Z"/>
              <path d="M4.84615 9.30226H4.38369C4.00154 9.30226 3.69138 8.98967 3.69138 8.60452C3.69138 8.21937 4.00154 7.90678 4.38369 7.90678H4.84615C5.22831 7.90678 5.53846 8.21937 5.53846 8.60452C5.53846 8.98967 5.22831 9.30226 4.84615 9.30226Z"/>
              <path d="M4.84615 15.8136H4.38369C4.00154 15.8136 3.69138 15.501 3.69138 15.1158C3.69138 14.7307 4.00154 14.4181 4.38369 14.4181H4.84615C5.22831 14.4181 5.53846 14.7307 5.53846 15.1158C5.53846 15.501 5.22831 15.8136 4.84615 15.8136Z"/>
              <path d="M13.6163 9.30226H7.61538C7.23323 9.30226 6.92308 8.98967 6.92308 8.60452C6.92308 8.21937 7.23323 7.90678 7.61538 7.90678H13.6163C13.9985 7.90678 14.3086 8.21937 14.3086 8.60452C14.3086 8.98967 13.9985 9.30226 13.6163 9.30226Z"/>
              <path d="M13.6163 15.8136H7.61538C7.23323 15.8136 6.92308 15.501 6.92308 15.1158C6.92308 14.7307 7.23323 14.4181 7.61538 14.4181H13.6163C13.9985 14.4181 14.3086 14.7307 14.3086 15.1158C14.3086 15.501 13.9985 15.8136 13.6163 15.8136Z"/>
              <path d="M11.3068 5.11582H6.69323C5.54677 5.11582 4.61631 4.17806 4.61631 3.02261V2.09322C4.61631 0.937762 5.54677 0 6.69323 0H11.3095C12.456 0 13.3865 0.937762 13.3865 2.09322V3.02261C13.3837 4.17806 12.4532 5.11582 11.3068 5.11582ZM6.69323 1.39548C6.31108 1.39548 6.00092 1.70807 6.00092 2.09322V3.02261C6.00092 3.40776 6.31108 3.72035 6.69323 3.72035H11.3095C11.6917 3.72035 12.0018 3.40776 12.0018 3.02261V2.09322C12.0018 1.70807 11.6917 1.39548 11.3095 1.39548H6.69323Z"/>
            </svg>
          </span> Status das demandas
        </button>
        <button (click)="getKanban('task-type')" 
                *ngIf="!['requester'].includes(currentUser?.group.name)"
                [class.selected]="activeTab === 'task-type'"
                class="selector-btn">
          <span>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="18" height="20" fill="none"><path d="M139.6 35.5a12 12 0 0 0 -17 0L58.9 98.8l-22.7-22.1a12 12 0 0 0 -17 0L3.5 92.4a12 12 0 0 0 0 17l47.6 47.4a12.8 12.8 0 0 0 17.6 0l15.6-15.6L156.5 69a12.1 12.1 0 0 0 .1-17zm0 159.2a12 12 0 0 0 -17 0l-63.7 63.7-22.7-22.1a12 12 0 0 0 -17 0L3.5 252a12 12 0 0 0 0 17L51 316.5a12.8 12.8 0 0 0 17.6 0l15.7-15.7 72.2-72.2a12 12 0 0 0 .1-16.9zM64 368c-26.5 0-48.6 21.5-48.6 48S37.5 464 64 464a48 48 0 0 0 0-96zm432 16H208a16 16 0 0 0 -16 16v32a16 16 0 0 0 16 16h288a16 16 0 0 0 16-16v-32a16 16 0 0 0 -16-16zm0-320H208a16 16 0 0 0 -16 16v32a16 16 0 0 0 16 16h288a16 16 0 0 0 16-16V80a16 16 0 0 0 -16-16zm0 160H208a16 16 0 0 0 -16 16v32a16 16 0 0 0 16 16h288a16 16 0 0 0 16-16v-32a16 16 0 0 0 -16-16z"/></svg>
          </span> Tarefas
        </button>
        <button (click)="getKanban('task-status')" 
        *ngIf="!['requester'].includes(currentUser?.group.name)"
        [class.selected]="activeTab === 'task-status'"
        class="selector-btn">
        <span>
            <i class="fa-solid fa-bars-progress"></i>
        </span> Status das Tarefas
        </button>
    </div>
    <div class="card-header py-3 bg-transparent table-filter d-flex align-items-center">
        <h6 class="table-title mb-0">{{ title }} ({{ demandsCount }})</h6>
        <button class="btn btn-primary ms-3 mb-0 border-radius-4 add-new" (click)="newDemand(requesterDemandsForm)" *ngIf="addNew">+ Nova Demanda</button>
  
        <div class="filters ms-auto">
            <button class="btn-filters btn-filters-square me-2 border-radius-8" (click)="sideBarFilter.showSideBarFilter = !sideBarFilter.showSideBarFilter" [class.show]="showFiltersBox">
                <i class="fa-solid fa-sliders"></i>
            </button>
        </div>
    </div>
    <div class="card-body pb-3 pt-0">
        <div class="kanban-container position-relative">
            <div class="row content g-3" #kanbanContent>
                <ng-container *ngIf="!skeleton">
                    <div class="col-custom" *ngFor="let s of status">
                        <h6 class="kanban-title text-center text-uppercase my-3" [style.color]="s.color">
                            <img [src]="s.avatar ?? 'assets/argon-dashboard-master/assets/img/default-user.png'" alt="" class="me-2 avatar rounded" *ngIf="activeTab === 'involved-users'">
                            <img src="assets/argon-dashboard-master/assets/img/claro/icons/status/{{s.icon}}" alt="" class="me-2" *ngIf="s.icon">
                            {{s.name}} ({{ s.demands.length ?? 0 }})
                        </h6>
                        <div class="card status card-body p-3 shadow-none">
                            <div
                                class="item-drop"
                                autoScrollDisabled="true">
                                <div class="card kanban-card pointer z-index-2 item"
                                    [style.border]="s.color"
                                    (click)="openDemandPopup ? showDemand(d.id, content) : ''" *ngFor="let d of s.demands">
                                    <div class="card-body py-2 px-3">
                                        <div class="demand d-flex align-items-center">
                                            <h6 class="mb-0 text-sm py-2 pointer demand-name">
                                                <p class="title text-xs">
                                                <span class="project-name mb-1 fw-bold d-block" *ngIf="d.project?.name">{{d?.project?.name}}</span>
                                                <span class="project-code me-2" *ngIf="d.project?.code && d?.item_id">{{ d.project?.code }}.{{ d?.item_id }}</span>
                                                <span class="demand-name">{{ d?.name }}</span>
                                                </p>
                                            </h6>
                                        </div>
                                        <p class="mb-0 deadline mt-1">
                                            <img src="assets/argon-dashboard-master/assets/img/claro/icons/calendar.svg" alt="" width="15">
                                            {{ d.created_at | date : 'dd/MM/yyyy' }}
                                        </p>
                                        <div class="d-flex align-items-center justify-content-between mt-3" style="line-height: normal">
                                            <div class="avatar-group" *ngIf="d.involved_users.length > 0">
                                                <div class="avatar avatar-lg avatar-sm rounded-circle" *ngFor="let u of d.involved_users">
                                                    <img alt="Image placeholder" [title]="u.name" [ngbTooltip]="u.name" [src]="u.avatar || 'assets/argon-dashboard-master/assets/img/default-user.png'">
                                                </div>
                                            </div>
                                            <div class="text-end d-flex align-items-center ms-auto">
                                                <div class="emoji me-2">{{getEmoji(d?.emoji_id)}}</div>
                                            </div>
                                            <div class="text-end d-flex align-items-center comments">
                                                <img src="assets/argon-dashboard-master/assets/img/claro/icons/chats_count.svg" width="19">
                                                <span class="text-xs ms-1">{{ d?.chats_count }}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>
                <ng-container *ngIf="skeleton">
                    <div class="col-3 mt-5" *ngFor="let i of [1,2,3,4]">
                        <ngx-skeleton-loader 
                            count="1" 
                            [theme]="{height: '55vh', width: '100%',borderRadius: '1rem'}">
                        </ngx-skeleton-loader>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</div>

<app-sidebar-filter
#sideBarFilter
[showActiveUsersViewAccordion]="activeTab === 'involved-users'"

></app-sidebar-filter>

<ng-template #content let-modal>
    <app-demands-form #demand [demand_id]="demand_id" (cancelModal)="modal.dismiss('Cross click')" [modal]="true"></app-demands-form>
</ng-template>

<ng-template #requesterDemandsForm let-modal>
    <app-requester-demands-form (close)="modal.dismiss('Cross click')"></app-requester-demands-form>
  </ng-template>