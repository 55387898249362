<div class="container-fluid">
<div class="row mt-4" *ngIf="!skeleton">
        <div class="col-lg-12 col-12 mx-auto">
            <div class="card card-body z-index-2 h-100 pb-4">
    
                <h6 class="mb-0">Tarefa</h6>
                <p class="text-sm mb-0">Gerencie as tarefas da sua área</p>

                <form [formGroup]="taskForm">
                    <div class="row align-items-end">
                        <div class="col-lg-12">
                            <label class="form-label mt-4">Nome</label>
                            <input type="text" class="form-control" formControlName="name">
                        </div>
                        <!-- <div class="col-lg-12">
                            <label class="form-label mt-4">Email</label>
                            <input type="text" class="form-control" formControlName="email">
                        </div> -->
                    </div>
                </form>
            </div>
        </div>
    </div>
    <div class="row mt-5">
        <div class="col-lg-4">
            <button type="button" 
                    name="button" 
                    [disabled]="loading" 
                    routerLink="/leadership/tasks" 
                    class="btn btn-light m-0">
                        Voltar
            </button>
        </div>
        <div class="col-lg-8 col-12 mx-auto">
            <div class="d-flex justify-content-end">
                <button type="button" 
                        name="button" 
                        [disabled]="loading" 
                        routerLink="/leadership/tasks" 
                        class="btn btn-light m-0">
                            Cancelar
                </button>
                <button type="button" 
                        name="button" 
                        [disabled]="loading || !taskForm.valid"
                        (click)="save(taskForm)"
                        class="btn bg-gradient-primary m-0 ms-2">
                            <span class="spinner-grow spinner-grow-sm" 
                                *ngIf="loading" role="status" 
                                aria-hidden="true">
                            </span>
                            {{ loading ? 'Salvando' : 'Salvar tarefa' }}
                </button>
            </div>
        </div>
    </div>
</div>