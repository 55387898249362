// shared.module.ts

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClient, HttpClientModule, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';

// Third-Party Modules
import { NgApexchartsModule } from 'ng-apexcharts';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxFileDropModule } from 'ngx-file-drop';
import { NgbModalModule, NgbModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { LottieModule } from 'ngx-lottie';
import { PageDefaultComponent } from './page-default/page-default.component';
import { BoxStatusComponent } from './components/box-status/box-status.component';
import { SafePipe } from './pipe/safe.pipe';
import { SlugifyPipe } from './pipe/slugify';
// ngxmask
import { NgxOtpInputModule } from 'ngx-otp-input';


import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatOptionModule } from '@angular/material/core'; // Importa o módulo para as opções
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { BsDatepickerConfig, BsDatepickerModule, BsLocaleService } from 'ngx-bootstrap/datepicker';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { ptBrLocale } from 'ngx-bootstrap/locale';
defineLocale('pt-br', ptBrLocale);




import player from 'lottie-web';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { DemandTableComponent } from './components/demand-table/demand-table.component';
import { BoxPieChartComponent } from './components/box-pie-chart/box-pie-chart.component';
import { BoxBarChartComponent } from './components/box-bar-chart/box-bar-chart.component';
import { SideMenuComponent } from './components/side-menu/side-menu.component';
import { NavComponent } from './components/nav/nav.component';
import { RouterModule } from '@angular/router';
import { FileUploadComponent } from './components/file-upload/file-upload.component';
import { BoxKanbanComponent } from './components/box-kanban/box-kanban.component';
import { DemandsFormComponent } from './components/demands-form/demands-form.component';
import { ChatComponent } from './components/chat/chat.component';
import { RequesterDemandsFormComponent } from './components/requester-demands-form/requester-demands-form.component';
import { EmojiSelectorComponent } from './components/emoji-selector/emoji-selector.component';
import { TasksComponent } from './components/tasks/tasks.component';
import { HistoryComponent } from './components/history/history.component';
import { BoxStatusOverviewComponent } from './components/box-status-overview/box-status-overview.component';
import { TimesheetComponent } from './components/timesheet/timesheet.component';
import { DefaultTableComponent } from './components/default-table/default-table.component';

import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { IconPickerModule } from 'ngx-icon-picker';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {OttomateNgxRruleModule} from 'ottomate-ngx-rrule';
import { OrderModule } from 'ngx-order-pipe';
import { NgxDaterangepickerBootstrapModule, NgxDaterangepickerLocaleService } from 'ngx-daterangepicker-bootstrap';

import { EventsComponent } from './components/events/events.component';
import { EventCalendarComponent } from './components/event-calendar/event-calendar.component';
import { FieldsComponent } from './components/fields/fields.component';
import { BoxDonutChartComponent } from './components/box-donut-chart/box-donut-chart.component';
import { BoxColumnChartComponent } from './components/box-column-chart/box-column-chart.component';
import { DashboardCardsComponent } from './components/dashboard-cards/dashboard-cards.component';
import { IconComponent } from './components/icon/icon.component';
import { SidebarFilterComponent } from './components/sidebar-filter/sidebar-filter.component';
import { UserSelectorComponent } from './components/user-selector/user-selector.component';


@NgModule({
  declarations: [
    PageDefaultComponent,
    BoxStatusComponent,
    DefaultTableComponent,
    DemandTableComponent,
    DemandsFormComponent,
    BoxPieChartComponent,
    BoxBarChartComponent,
    BoxDonutChartComponent,
    BoxColumnChartComponent,
    BoxKanbanComponent,
    SideMenuComponent,
    NavComponent,
    SafePipe,
    SlugifyPipe,
    FileUploadComponent,
    ChatComponent,
    RequesterDemandsFormComponent,
    EmojiSelectorComponent,
    TasksComponent,
    HistoryComponent,
    BoxStatusOverviewComponent,
    TimesheetComponent,
    EventsComponent,
    EventCalendarComponent,
    FieldsComponent,
    DashboardCardsComponent,
    IconComponent,
    SidebarFilterComponent,
    UserSelectorComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    HttpClientModule,
    
    // Third-Party Modules
    NgApexchartsModule,
    DragDropModule,
    CKEditorModule,
    NgMultiSelectDropDownModule,
    NgxSkeletonLoaderModule,
    NgSelectModule,
    NgbModalModule,
    NgbTooltipModule,
    NgbModule,
    NgxFileDropModule,
    LottieModule.forRoot({ player: playerFactory }),
    // NgxMaskModule.forRoot(),
    NgxOtpInputModule,
    NgxMaskDirective, // Diretiva para uso em templates
    NgxMaskPipe,      // Pipe para usar em templates
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    MatNativeDateModule,
    MatAutocompleteModule,
    MatOptionModule,
    MatCheckboxModule,
    MatChipsModule,
    MatIconModule, 
    MatButtonModule,
    FontAwesomeModule,
    IconPickerModule,
    OttomateNgxRruleModule,
    BsDatepickerModule,
    SweetAlert2Module.forRoot(),
    NgApexchartsModule,
    OrderModule,
    NgxDaterangepickerBootstrapModule.forRoot()

  ],
  providers: [
    provideNgxMask(), // Fornece as configurações padrão do ngx-mask
    [provideHttpClient(withInterceptorsFromDi())],
    NgxDaterangepickerLocaleService
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,

    // Third-Party Modules
    NgApexchartsModule,
    NgMultiSelectDropDownModule,
    CKEditorModule,
    DragDropModule,
    NgxSkeletonLoaderModule,
    NgSelectModule,
    NgbModalModule,
    NgbTooltipModule,
    NgbModule,
    NgxFileDropModule,
    LottieModule,
    NgxMaskDirective, // Diretiva para uso em templates
    NgxMaskPipe,      // Pipe para usar em templates
    NgxOtpInputModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    MatNativeDateModule,
    MatAutocompleteModule,
    MatOptionModule,
    MatCheckboxModule,
    MatChipsModule,
    MatIconModule, 
    MatButtonModule,
    FontAwesomeModule,
    IconPickerModule,
    OttomateNgxRruleModule,
    BsDatepickerModule,
    NgApexchartsModule,
    OrderModule,
    NgxDaterangepickerBootstrapModule,

    PageDefaultComponent,
    BoxStatusComponent,
    DemandTableComponent,
    DemandsFormComponent,
    BoxPieChartComponent,
    BoxBarChartComponent,
    BoxDonutChartComponent,
    BoxColumnChartComponent,
    BoxKanbanComponent,
    SideMenuComponent,
    NavComponent,
    FileUploadComponent,
    ChatComponent,
    RequesterDemandsFormComponent,
    EmojiSelectorComponent,
    TasksComponent,
    HistoryComponent,
    BoxStatusOverviewComponent,
    TimesheetComponent,
    DefaultTableComponent,
    FieldsComponent,
    DashboardCardsComponent,
    IconComponent,
    SidebarFilterComponent,
    UserSelectorComponent,
    
    EventsComponent,
    EventCalendarComponent,
    
    SafePipe,
    SlugifyPipe
  ]
})
export class SharedModule { 

  constructor( private bsLocaleService: BsLocaleService){
    this.bsLocaleService.use('pt-br');//fecha en español, datepicker
  }

}

export function playerFactory() {
  return player;
}

