import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TaskService } from 'src/app/shared/services/demands/task.service';
import { UserService } from 'src/app/shared/services/user-management/user.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-tasks-management-form',
  templateUrl: './tasks-management-form.component.html',
  styleUrl: './tasks-management-form.component.scss'
})
export class TasksManagementFormComponent {

  skeleton = false
  loading = false

  currentUser:any = null;

  taskForm: any

  constructor(
    private formBuilder: FormBuilder,
    private taskService: TaskService,
    private userService: UserService,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {

    this.currentUser = this.userService.getLocalUser();

    this.route.paramMap.subscribe(params => {
      let id = params.get('id')
      if (id) this.getById(id)
    })

    this.taskForm = this.formBuilder.group({
      'id':       [''],
      'name':     ['', Validators.required],
      'area_id':  [this.currentUser.area_id, Validators.required],
      // 'color':    ['', Validators.required],
    })

  }

  getById(id: any) {
    this.skeleton = true
    this.taskService.getById(id).subscribe({
      next: (result: any) => {
        this.taskForm.patchValue(result)
        this.skeleton = false
      },
      error: (err) => console.log(err)
    })
  }

  save(data: any) {
    this.loading = true
    this.taskService.save(data.value).subscribe({
      next: (result: any) => {
        this.loading = false

        Swal.fire({
          position: 'top-end',
          icon: 'success',
          text: 'Tarefa salva com sucesso',
          showConfirmButton: false,
          timer: 1500
        })
        
        this.router.navigateByUrl(`/leadership/tasks`)
      },
      error: (error) => {
        console.log(error)
        this.loading = false
      }
    })
  }

}