import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { BaseService } from 'src/app/core/shared/services/base.service';

@Injectable({
  providedIn: 'root'
})
export class ThemeService extends BaseService {

  async loadTheme(): Promise<void> {
    console.log(window.location.hostname);
    try {
      const settings:any = await firstValueFrom(this.get(`client/settings`));
      
      if (!settings || settings.length === 0) {
        console.warn("Nenhuma configuração de tema encontrada.");
        return;
      }

      console.log(settings);
      const colors = settings.theme.colors;
      let rootStyles = document.documentElement.style;

      Object.keys(colors).forEach(color => {
        Object.keys(colors[color]).forEach(shade => {
          rootStyles.setProperty(`--${color}-${shade}`, colors[color][shade]);
        });
      });

      // Definições gerais do tema
      rootStyles.setProperty("--font-size", settings.theme.font_size);
      rootStyles.setProperty("--font-family", settings.theme.font_family);
      rootStyles.setProperty("--primary-color", settings.theme.primary_color);
      rootStyles.setProperty("--secondary-color", settings.theme.secondary_color);
      rootStyles.setProperty("--gradient-sidemenu-primary", settings.theme.gradient_sidemenu_primary);
      rootStyles.setProperty("--gradient-sidemenu-secondary", settings.theme.gradient_sidemenu_secondary);
      rootStyles.setProperty("--bg-primary", settings.theme.bg_primary);
      rootStyles.setProperty("--btn-primary", settings.theme.btn_primary);
      rootStyles.setProperty("--background-color", settings.theme.background_color);
      
    } catch (error) {
      console.error("Erro ao carregar tema:", error);
    }
  }
}
