import { Component, OnInit } from '@angular/core';
import { AreaService } from 'src/app/shared/services/user-management/area.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-areas-management',
  templateUrl: './areas-management.component.html',
  styleUrl: './areas-management.component.scss'
})
export class AreasManagementComponent {
  areas = [];
  skeleton = true;
  query: any;
  orderBy = 'order'
  order = 'desc';
  page = 1;

  constructor(
    private areaService: AreaService
  ) { }

  ngOnInit(): void {
    this.get()
  }

  get() {
    this.areaService.getAll({
      limit:  null, 
      paginate: 10,
      query:  this.query,
      orderBy: this.orderBy,
      order:  this.order,
      page:   this.page 
    }).subscribe({
      next: (result:any) => {
        this.skeleton   = false
        this.areas = result;
        console.log(this.areas)
      },
      error: (err) => console.log(err)
    })
  }

  delete(item: any) {
    Swal.fire({
      icon: 'warning',
      text: `Deseja remover o campo ${item.name}?`,
      showCancelButton: true,
      confirmButtonText: 'Excluir',
      cancelButtonText: 'Cancelar',
      confirmButtonColor: '#f5365c',
    }).then(result => {
      if (result.value) {
        if (item.id) {
          this.areaService.deleteById(item.id).subscribe({
            next: () => this.get(),
            error: (err) => console.log(err)
          })
        } 
      }
    })
  }

  setOrder(order: any) {
    this.order = order
    this.get()
  }

  setPage(p: number) {
    this.page = p
    this.get()
  }

  setSearch(query: string) {
    this.query = query
    this.get()
  }
 
}