import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from 'src/app/core/shared/services/base.service';

@Injectable({
  providedIn: 'root'
})

export class ClientsService extends BaseService {

  public client_id;
  public client_settings:any

  constructor(http: HttpClient) {
    super(http);
    this.client_id      = localStorage.getItem('client_id') || '';
    this.client_settings  = localStorage.getItem('client_settings') || ''
  }

  save(data: FormData) {
    return this.post(`clients`, data)
  }

  getAll() {
    return this.get(`clients`)
  }

  getById(id: number) {
    return this.get(`clients/${id}`)
  }

  getByHash(hash: string) {
    return this.get(`clients/h/${hash}`)
  }

  deleteById(id: any) {
    return this.delete(`clients/${id}`)
  }

  getUserClientSettings() { 
    let clientSettings = localStorage.getItem('clientSettings');

    if (!clientSettings && clientSettings != null) {
      this.getClientSettings().subscribe((config:any) => {
        localStorage.setItem('clientSettings', JSON.stringify(config.settings));
        return config.settings;
      });
    } else {
      return JSON.parse(clientSettings);
    }
  }

  getClientSettings(){
    return this.get('client/settings');
  }

  getClientNavigation() {
    return this.get(`clients/select/logged`);
  }

  setClientNavigation(id: any) {
    this.client_id = id;
    console.log(this.client_id);
    return this.post(`clients/select`, {site_id: this.client_id})
  }
}
